import {
  afterNextRender,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild
} from '@angular/core';
import {EditorModule} from "primeng/editor";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {AppService} from "../../app.service";
import moment from "moment/moment";
import {PicturePreviewService} from "../../picture-preview/picture-preview.service";
import {VideoPlayerService} from "../../video-player/video-player.service";
import {LoaderService} from "../../loader/loader.service";
import {SecurityQueryResult} from "../../securityQuery/securityQuery.component";
import {SecurityQueryService} from "../../securityQuery/securityQuery.service";
import {MapService} from "../../map/map.service";
import {FormsModule} from "@angular/forms";
import {CalendarModule} from "primeng/calendar";
import {FloatLabelModule} from "primeng/floatlabel";
import {
  JournalPreviewEntityAssetsComponent
} from "../../journal-preview/journal-preview-entity-assets/journal-preview-entity-assets.component";
import {fromEvent} from "rxjs";
import {
  BaseService,
  Journal,
  JournalEntity,
  JournalEntityAsset,
  RegistryService,
  Track,
} from "logbuch-client-sdk";
import {SocketChannelEvents} from "logbuch.misc";
import {AlertModalService, AlertType} from "../../alert-modal/alert-modal.service";
import {AudioRecordAssignment} from "logbuch-client-sdk/lib/models/AudioRecordAssignment";
import {Tooltip} from "primeng/tooltip";
import {ScrollPositionService} from "../../../services/scrollPosition.service";

@Component({
  selector: 'app-journal-entities',
  imports: [
    EditorModule,
    NgForOf,
    NgIf,
    FormsModule,
    CalendarModule,
    FloatLabelModule,
    JournalPreviewEntityAssetsComponent,
    Tooltip,
    NgClass
  ],
  templateUrl: './journal-entities.component.html',
  standalone: true,
  styleUrl: './journal-entities.component.scss'
})
export class JournalEntitiesComponent implements AfterViewInit {

  // @ViewChildren('entities') entityElements!: QueryList<ElementRef>;

  @ViewChild('fileInput', {static: false}) fileInput!: ElementRef;

  @Output() save = new EventEmitter<void>();
  @Output() trackSelected = new EventEmitter<Track>();
  @Output() entitySelected = new EventEmitter<JournalEntity>();
  @Output() previewOpened = new EventEmitter<JournalEntity>();

  @Output() thumbnailUploaded = new EventEmitter<JournalEntity>();

  @Input() journal?: Journal;
  @Input() deleteJournalEntities: number[] = [];

  @Input() editMode: boolean = false;
  @Output() editModeChange = new EventEmitter<boolean>();

  selectTrackOfEntity?: JournalEntity;

  selectedAsset?: JournalEntityAsset;
  selectedEntity?: JournalEntity;

  scrollSub$: any;

  constructor(public appService: AppService,
              private services: RegistryService,
              private alertService: AlertModalService,
              public base: BaseService,
              public previewService: PicturePreviewService,
              private videoPreview: VideoPlayerService,
              private z: NgZone,
              private cd: ChangeDetectorRef,
              private loader: LoaderService,
              private mapService: MapService,
              private scrollPositionService: ScrollPositionService,
              private securityQuery: SecurityQueryService) {
    afterNextRender(() => {
      this.scrollSub$ = fromEvent(window, 'scroll', {capture: true});
      this.scrollSub$.subscribe(() => {
        this.z.run(() => {
          if (this.journal?.journals.some(x => !x.isVisible)) {
            Promise.resolve().then(() => {
              this.visibilityCheck();
              this.cd.detectChanges();
            });
          }
        });
      })
    });
  }

  ngAfterViewInit() {
    Promise.resolve().then(() => {
      this.visibilityCheck();
      this.cd.detectChanges();
    });

    this.listenWebSocket();
  }

  visibilityCheck() {
    for (const entity of this.journal?.journals ?? []) {
      const element = document.getElementById(`entity#${entity!.id}`) ?? document.getElementById(`${entity!.id}`);
      if (element != null) {
        const rect = element.getBoundingClientRect();
        const elementHeight = rect.bottom - rect.top;
        const visibleHeight = Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
        if (visibleHeight >= elementHeight / 2) {
          entity.isVisible = true;
        }
      }
    }
  }

  tryEdit() {
    if (this.journal?.readonly) {
      return;
    }
    this.editModeChange.emit(true);
  }

  leaveEdit() {
    this.save.emit();
  }

  getTracksByEntity(entity: JournalEntity) {
    if (this.journal?.tracks == null || this.journal?.tracks.length == 0) {
      return [];
    }
    return this.journal?.tracks.filter(x => new Date(x.createdAt).getDate() == new Date(entity.date).getDate());
  }

  formatTime(date: Date) {
    moment.locale('de');
    return moment(date).format('HH:mm') + ' Uhr';
  }

  getDate(journal: JournalEntity) {
    moment.locale('de');
    return moment(journal.date).format('dddd, DD.MM.YYYY');
  }

  async deleteEntity(entity: JournalEntity) {

    if (this.appService.isDirty) {
      this.alertService.show('Es gibt ungespeicherte Änderungen. Bitte speichere diese zuerst.');
      return;
    }

    if (await this.securityQuery.show('Möchtest du den ausgewählten Eintrag wirklich löschen?', true, true, false) != SecurityQueryResult.Yes) {
      return;
    }

    try {
      this.loader.visibility = true;
      if (entity.id != null && entity.id > 0) {
        await this.services.journal.deleteEntity(entity.id);
      } else {
        this.journal?.journals.splice(this.journal?.journals.indexOf(entity), 1);
      }
      window.location.reload();
    } finally {
      this.loader.visibility = false;
    }
  }

  async newEntity() {

    const lastEntity = this.journal?.journals[this.journal?.journals.length - 1];
    const nextDate = moment(lastEntity?.dateOnly).add(1, "days").toDate();

    const entity = new JournalEntity();
    entity.id = -1;
    entity.voiceEntries = [];
    entity.date = nextDate;
    entity.dateOnly = nextDate;
    entity.assets = [];
    entity.text = 'Erzähle an dieser Stelle etwas von dem Tag auf See...';
    this.journal?.journals.push(entity);
    this.appService.isDirty = true;
  }

  openFileDialogForThumbnail(asset: JournalEntityAsset, entity: JournalEntity) {
    this.selectedAsset = asset;
    this.selectedEntity = entity;
    this.fileInput.nativeElement.click();
  }

  async postThumbnail(event: any) {
    try {
      const file: File = event.target.files[0];
      if (file) {
        this.loader.visibility = true;
        await this.services.journal.postThumbnail(this.selectedAsset?.id!, file);
        this.thumbnailUploaded.emit(this.selectedEntity!);
      }
    } finally {
      this.loader.visibility = false;
    }
  }

  getText(entity: JournalEntity) {
    return entity.text.replace(/&nbsp;/g, ' ');
  }

  listenWebSocket() {
    this.appService.websocket.client.on(SocketChannelEvents.JournalUpdated,
      async (assignment: AudioRecordAssignment) => {
        if (assignment.journalEntity?.journal.id == this.journal?.id && this.journal !== undefined) {
          this.journal.journals = this.journal.journals.map(x => {
            if (x.id == assignment.journalEntity!.id) {
              if (x.assignments == undefined) {
                x.assignments = [];
              }
              x.assignments.push(assignment);
            }
            return x;
          });

          this.alertService
            .show(
              'Zu diesem Logbuch-Eintrag wurden neue Spracheinträge hinzugefügt.',
              10000,
              AlertType.Success
            );
        }
    });
  }

}
