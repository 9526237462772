<div class="map-wrapper">
  <div id="map-journal"></div>
  <div *ngIf="!mapLoaded" class="loader">
    <p-progress-spinner strokeWidth="4" fill="#ddd" animationDuration=".75s" [style]="{ width: '80px', height: '80px' }" />
    <label>Karte wird geladen...</label>
  </div>
  <div *ngIf="mapLoaded && !positionsExists"
       style="background-image: url('assets/bg/map-bg.png')"
       class="overlay">
    <div>
      <i class="fa-solid fa-circle-info"></i>
      <div>
        <label>Dein Törn wartet darauf, mit Leben gefüllt zu werden!</label>
        <span>Lade deine Medien hoch und lass deine Reiseerlebnisse lebendig werden.</span>
      </div>
    </div>
  </div>
</div>
