<div class="core-wrapper">
  <div class="core-container">
    <app-header [isAuth]="appService.isAuth"
                [userDetails]="appService.userDetails"
                [displayHeadline]="displayHeadline()"
                [stats]="stats"
                (searchValueChanged)="search($event)"/>
    <div class="content">
      <router-outlet />
    </div>
  </div>
  <app-footer *ngIf="displayHeadline()"/>
</div>

<app-crew-editor/>
<app-sort/>
<app-alert-modal/>
<app-video-player/>
<app-security-query/>
<app-issue/>
<app-verify-session/>
<app-loader/>
