<div class="wrapper">
  <div class="container">
    <div class="title">
      <i class="fa fa-exclamation-triangle"></i>
      <label>Huston, wir haben ein Problem!</label>
    </div>
    <div class="text">
      Wir haben aktuell leider interne Probleme mit unserer System-Infrastruktur. Wir arbeiten mit Hochdruck an der Behebung des Problems und bitten um Ihr Verständnis.
    </div>
    <small>
      Du wirst automatisch weitergeleitet, sobald das Problem behoben ist.
    </small>
  </div>
</div>
