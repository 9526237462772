import {Component} from '@angular/core';
import {NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {SecurityQueryService} from "./securityQuery.service";
import {Button} from "primeng/button";

@Component({
  selector: 'app-security-query',
  imports: [
    NgIf,
    FormsModule,
    Button
  ],
  templateUrl: './securityQuery.component.html',
  standalone: true,
  styleUrls: ['./securityQuery.component.scss']
})
export class SecurityQueryComponent {

  constructor(public service: SecurityQueryService) {
  }

  // tslint:disable-next-line:typedef
  get result() {
    return SecurityQueryResult;
  }

}

export enum SecurityQueryResult {
  Yes,
  No,
  Cancel
}
