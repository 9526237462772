<div class="wrapper">
  <div class="container">
    <div class="head">
      <label>Lade deine Freunde zum Törn ein!</label>
      <i (click)="close.emit()" class="pi pi-times-circle"></i>
    </div>

    <span>
      Möchtest du deine Segelerlebnisse mit Familie oder Freunden teilen? Gib einfach ihre E-Mail-Adresse und ihren Namen ein und klicke auf "Freigeben" - schon können sie alle Details deines Törns sehen und mitverfolgen.
    </span>

    <div class="action-buttons">
      <p-autocomplete [(ngModel)]="name"
                      [fluid]="true"
                      placeholder="Name"
                      [suggestions]="nameList"
                      (completeMethod)="searchName($event)"
                      (onSelect)="onNameSelect($event)"></p-autocomplete>
      <p-autocomplete [(ngModel)]="email"
                      [fluid]="true"
                      placeholder="E-Mail"
                      [suggestions]="emailList"
                      (completeMethod)="searchMail($event)"
                      [minLength]="3"
                      (onSelect)="onEmailSelect($event)"></p-autocomplete>
      <p-button (click)="share()" label="Freigeben" icon="pi pi-share-alt"></p-button>
    </div>

    <!-- <p-inputGroup>
      <p-inputGroupAddon>https://törn.link/</p-inputGroupAddon>
      <input [(ngModel)]="permalink" type="text" pInputText placeholder="Permalink" style="min-width: 128px"/>
      <button (click)="shareLink()" *ngIf="journal?.permalink != null" type="button" pButton icon="pi pi-share-alt"
              class="p-button-info m-hide"
              pTooltip="Teilen" tooltipPosition="bottom"></button>
      <button (click)="createMailTemplate()" *ngIf="journal?.permalink != null" type="button" pButton icon="pi pi-at"
              class="p-button-info m-hide"
              pTooltip="Teilen per E-Mail" tooltipPosition="bottom"></button>
      <button (click)="generatePermalink()" type="button" pButton icon="pi pi-refresh" class="p-button-secondary m-hide"
              pTooltip="Neuen Link generieren" tooltipPosition="bottom"></button>
      <button (click)="deletePermalink()" *ngIf="journal?.permalink != null" type="button" pButton icon="pi pi-trash"
              class="p-button-danger m-hide"
              pTooltip="Link wiederrufen" tooltipPosition="bottom"></button>
      <button (click)="share()" class=" m-hide" style="width: 256px;" *ngIf="journal?.permalink == null" type="button"
              pButton
              label="Freigeben"></button>
    </p-inputGroup> -->

    <!-- <p-inputGroup class="normal-hide">
      <button (click)="shareLink()" *ngIf="journal?.permalink != null" type="button" pButton icon="pi pi-share-alt"
              class="p-button-info"
              pTooltip="Teilen" tooltipPosition="bottom"></button>
      <button (click)="createMailTemplate()" *ngIf="journal?.permalink != null" type="button" pButton icon="pi pi-at"
              class="p-button-info"
              pTooltip="Teilen per E-Mail" tooltipPosition="bottom"></button>
      <button (click)="generatePermalink()" type="button" pButton icon="pi pi-refresh" class="p-button-secondary"
              pTooltip="Neuen Link generieren" tooltipPosition="bottom"></button>
      <button (click)="deletePermalink()" *ngIf="journal?.permalink != null" type="button" pButton icon="pi pi-trash"
              class="p-button-danger"
              pTooltip="Link wiederrufen" tooltipPosition="bottom"></button>
      <button (click)="share()" style="width: 256px;" *ngIf="journal?.permalink == null" type="button" pButton
              label="Freigeben"></button>
    </p-inputGroup> -->

  </div>
</div>
