import {Component, Input} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {SortService} from "./sort.service";

@Component({
  selector: 'app-sort',
  imports: [
    NgIf,
    NgForOf
  ],
  templateUrl: './sort.component.html',
  standalone: true,
  styleUrl: './sort.component.scss'
})

export class SortComponent {
  constructor(public service: SortService) {
    //
  }
}

export enum BaseSortDescription {
  default = 'Standardsortierung'
}
