import {Injectable} from "@angular/core";
import {ScrollService} from "logbuch-client-sdk";

@Injectable({
  providedIn: 'root'
})
export class ScrollPositionService {
  private readonly SCROLL_KEY = 'lastScrollPosition';
  private readonly MAX_SCROLL_ATTEMPTS = 5;
  private readonly SCROLL_RETRY_DELAY = 100; // milliseconds

  constructor(public extendedScrollService: ScrollService) {
    //
  }

  saveScrollPosition(): void {
    const position = {
      x: window.scrollX,
      y: window.scrollY,
      path: window.location.pathname,
      fragment: window.location.hash.slice(1)
    };
    sessionStorage.setItem(this.SCROLL_KEY, JSON.stringify(position));
  }

  async restoreScrollPosition(): Promise<void> {
    const savedPosition = sessionStorage.getItem(this.SCROLL_KEY);
    if (!savedPosition) return;

    const position = JSON.parse(savedPosition);
    if (position.path !== window.location.pathname) return;

    if (document.readyState !== 'complete') {
      await new Promise(resolve => {
        window.addEventListener('load', resolve, { once: true });
      });
    }

    let attempts = 0;
    const tryScroll = async () => {
      if (position.fragment) {
        const element = document.getElementById(position.fragment);
        if (element) {
          element.scrollIntoView({ behavior: 'auto' });
          return true;
        }
      }

      window.scrollTo({
        left: position.x,
        top: position.y,
        behavior: 'auto'
      });

      const currentScroll = window.scrollY;
      return Math.abs(currentScroll - position.y) < 10;
    };

    const attemptScroll = async () => {
      if (attempts >= this.MAX_SCROLL_ATTEMPTS) return;

      const success = await tryScroll();
      if (!success) {
        attempts++;
        await new Promise(resolve => setTimeout(resolve, this.SCROLL_RETRY_DELAY));
        await attemptScroll();
      }
    };

    await attemptScroll();
  }

  clearScrollPosition(): void {
    sessionStorage.removeItem(this.SCROLL_KEY);
  }
}
