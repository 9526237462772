import {Injectable} from "@angular/core";

@Injectable()
export class NativeAppService {
  private isInNativeApp = window.hasOwnProperty('flutter_inappwebview');

  async callNative(action: string, data?: any): Promise<any> {
    if (this.isInNativeApp) {
      return await (window as any).flutter_inappwebview
        .callHandler('nativeApp', action, data);
    }
    return null;
  }

  constructor() {
    window.addEventListener('nativeAppMessage', (event: any) => {
      console.log('Nachricht von Native App:', event.detail);
    });
  }
}
