<div class="title">
  <div class="header">
    <div class="main">
      <div *ngIf="isAuth" class="search">
        <p-menu #menu [model]="menuItems" [popup]="true">
          <ng-template #start>
            <div class="row" style="align-items: center; gap: 0.5rem; padding: 0.5rem;">
              <img ngSrc="assets/logo2.png" width="45" height="45" alt="Logo">
              <div class="col" style="text-align: left">
                <label style="font-size: 0.9rem;">DILOG</label>
                <small style="font-size: 0.75rem; font-weight: normal">Das digitale Logbuch</small>
              </div>
            </div>
          </ng-template>
          <ng-template #submenuheader let-item>
            <div style="text-align: left; font-size: 1rem; width: 100%;">{{ item.label }}</div>
          </ng-template>
          <ng-template #item let-item>
            <a (click)="navigate(item.routerLink)" pRipple class="flex items-center p-menu-item-link">
              <span [class]="item.icon"></span>
              <span style="font-size: 0.9rem; text-transform: none">{{ item.label }}</span>
            </a>
          </ng-template>
          <ng-template #end>
            <div routerLink="/profile" class="menu-profile-card">
              <p-avatar image="assets/user.svg"/>
              <div class="col" style="gap: 0.25rem;">
                <small style="font-size: 0.6rem; font-weight: normal">Dein Profil</small>
                <label style="font-size: 0.8rem; text-align: left">{{ DisplayName }}</label>
                <!-- <div class="row" style="gap: 0.25rem;">
                  <p-tag *ngIf="isAdmin" severity="danger" value="Admin" [style]="{'font-size': '0.5rem', 'text-transform': 'none'}"/>
                  <p-tag *ngIf="ownMembership !== undefined" severity="warn" value="Premium"
                         [style]="{'font-size': '0.5rem', 'text-transform': 'none', 'background': '#ffa600'}"/>
                </div> -->
              </div>
            </div>
          </ng-template>
        </p-menu>
        <p-button (onClick)="menu.toggle($event)" label="Menü" icon="pi pi-bars" severity="primary" [rounded]="true"/>
        <i *ngIf="!displayHeadline" routerLink="/journal" class="pi pi-home"></i>
        <i *ngIf="searchAvailable && !searchVisible" (click)="showSearch()" class="m-hide pi pi-search"></i>
        <input *ngIf="searchAvailable && searchVisible" [(ngModel)]="searchValue"
               (ngModelChange)="search()" (focusout)="searchVisible = false"
               type="text" placeholder="Logbuch durchsuchen" id="search-field">
      </div>
      <div *ngIf="displayHeadline" routerLink="/journal" class="main-title">
        <label>Logbuch</label>
        <span *ngIf="isAuth && DisplayName">von {{ DisplayName }}</span>
        <span *ngIf="DisplayName == undefined">presented by DiLog</span>
        <!-- <span *ngIf="!isAuth">Willkommen, probiere es gleich aus!</span>-->
      </div>
      <div *ngIf="isAuth" class="profile">
        <div *ngIf="stats !== undefined">
          <div class="name">Auf einen Blick!</div>
          <div class="details">
            <div pTooltip="Anzahl an Törns" tooltipPosition="bottom">
              <i class="pi pi-book"></i>
              <label>{{ stats.journals }} Törn<span *ngIf="stats.journals > 1">s</span></label>
            </div>
            <div pTooltip="zurückgelegte Strecke in Meilen" tooltipPosition="bottom">
              <i class="pi pi-map"></i>
              <label>{{ stats.miles }} NM</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span class="splitter"></span>
    <!-- <app-nav/> -->
  </div>
</div>
