import { Component } from '@angular/core';

@Component({
  selector: 'app-nmea-connect-preview',
  imports: [],
  templateUrl: './nmea-connect-preview.component.html',
  standalone: true,
  styleUrl: './nmea-connect-preview.component.scss'
})
export class NmeaConnectPreviewComponent {

}
