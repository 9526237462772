import * as i0 from '@angular/core';
import { Injectable, Inject, NgModule } from '@angular/core';
import { Subject, catchError, EMPTY } from 'rxjs';
import * as i2 from '@angular/common/http';
import { HttpEventType } from '@angular/common/http';
import * as Forge from 'node-forge';
import _ from 'lodash';
import { startRegistration, startAuthentication } from '@simplewebauthn/browser';
import { io } from 'socket.io-client';
class StringHelper {
  generateRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  generateRandomAlfaString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  static isNullOrEmpty(value) {
    return value === null || value === undefined || value === '';
  }
  static {
    this.ɵfac = function StringHelper_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || StringHelper)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: StringHelper,
      factory: StringHelper.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StringHelper, [{
    type: Injectable
  }], null, null);
})();
class BaseService {
  get url() {
    if (!this.apiUrl.endsWith('/') && !this.apiPath.startsWith('/')) {
      return `${this.apiUrl}/${this.apiPath}`;
    }
    return `${this.apiUrl}${this.apiPath}`;
  }
  get host() {
    return this.apiUrl;
  }
  get httpOptions() {
    return {
      headers: {
        Authorization: this.allowLocalStorage ? this.getAuthorizationToken() : '',
        'X-Gateway-Identifier': this.selectedGateway > 0 ? this.selectedGateway.toString() : '',
        'X-Socket-Id': this.allowLocalStorage ? sessionStorage.getItem('Socket-ID') || '' : ''
      }
    };
  }
  constructor(stringHelper, http, apiUrl = 'http://localhost:3000', apiPath = '/api/v1', httpLogging = false, allowLocalStorage = true) {
    this.stringHelper = stringHelper;
    this.http = http;
    this.apiUrl = apiUrl;
    this.apiPath = apiPath;
    this.httpLogging = httpLogging;
    this.allowLocalStorage = allowLocalStorage;
    this.errorOccurred = new Subject();
    this.unauthorizedErrorOccurred = new Subject();
    this.selectedGateway = -1;
    this.progress = -1;
    //
  }
  executeRequest(path, method, body = {}, onMaster = false, extendedOptions = false, responseType, handleError = true) {
    const url = path.startsWith('http') ? path : `${this.url}${path}`;
    return new Promise((resolve, reject) => {
      this.executeRequestGetObserver(url, method, body, reject, !path.startsWith('http'), extendedOptions, responseType, handleError).subscribe(result => {
        resolve(result);
      });
    });
  }
  executeRequestGetObserver(url, method, body = {}, reject, withOptions = true, withExtendedOptions = false, responseType, handleError = true) {
    return this.getBaseRequest(url, method, body, withOptions, withExtendedOptions, responseType).pipe(catchError(error => {
      this.handleError(error, !handleError);
      reject();
      return EMPTY;
    }));
  }
  getBaseRequest(url, httpMethod, body = {}, withOptions = true, withExtendedOptions, responseType) {
    this.log(`Request: ${url}, Method: ${HttpMethod[httpMethod]}, withOptions: ${withOptions}, withExtendedOptions: ${withExtendedOptions}`);
    const extendedOptions = {
      ...this.httpOptions,
      reportProgress: true,
      observe: 'events'
    };
    const options = withExtendedOptions ? extendedOptions : withOptions ? {
      ...this.httpOptions,
      responseType
    } : {};
    switch (httpMethod) {
      case HttpMethod.GET:
        return this.http.get(url, options);
      case HttpMethod.POST:
        return this.http.post(url, body, options);
      case HttpMethod.PUT:
        return this.http.put(url, body, options);
      case HttpMethod.PATCH:
        return this.http.patch(url, body, options);
      case HttpMethod.DELETE:
        return this.http.delete(url, options);
      case HttpMethod.HEAD:
        return this.http.head(url, options);
    }
  }
  handleError(error, ignore = false) {
    if (error?.status === 429 && !ignore) {
      this.errorOccurred.next('\'Houston, wir haben ein Problem! Bitte versuchen Sie es später erneut.\'');
    }
    if (error?.status === 401 && !ignore) {
      this.log(error);
      this.unauthorizedErrorOccurred.next();
      // this.errorOccurred.next('Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.')
    } else if (!(error.error instanceof Error)) {
      if (error.error?.description_de != null && !ignore) {
        this.errorOccurred.next(error.error?.description_de);
        this.log(error.error?.description_de);
      } else if (error.error?.errors != null && !ignore) {
        let message = '';
        error.error?.errors.forEach(x => {
          if (message != '') {
            message += '<br>';
          }
          message += x.description;
        });
        this.errorOccurred.next(message);
        this.log(message);
      } else if (error.url != null && error.status != 0) {
        const message = `Fehler beim Aufruf von ${error.url} (${error.status}).`;
        this.log(`${LogPrefix.E} ${message}`);
      } else {
        this.log(`${LogPrefix.E} ${error.message}`);
      }
    }
  }
  getAuthorizationToken() {
    let token = localStorage.getItem('sessionID');
    if (token == null) {
      token = sessionStorage.getItem('sessionID');
      if (token == null) {
        token = '';
      }
    }
    return token;
  }
  setAuthorizationToken(token) {
    localStorage.setItem('sessionID', token);
  }
  generateUniqueId() {
    return this.stringHelper.generateRandomString(32);
  }
  log(message) {
    if (this.httpLogging) {
      console.log(message);
    }
  }
  static {
    this.ɵfac = function BaseService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BaseService)(i0.ɵɵinject(StringHelper), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject('apiUrl'), i0.ɵɵinject('apiPath'), i0.ɵɵinject('httpLogging'), i0.ɵɵinject('allowLocalStorage'));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BaseService,
      factory: BaseService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseService, [{
    type: Injectable
  }], () => [{
    type: StringHelper
  }, {
    type: i2.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['apiUrl']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['apiPath']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['httpLogging']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['allowLocalStorage']
    }]
  }], null);
})();
var HttpMethod;
(function (HttpMethod) {
  HttpMethod[HttpMethod["GET"] = 0] = "GET";
  HttpMethod[HttpMethod["POST"] = 1] = "POST";
  HttpMethod[HttpMethod["PUT"] = 2] = "PUT";
  HttpMethod[HttpMethod["PATCH"] = 3] = "PATCH";
  HttpMethod[HttpMethod["DELETE"] = 4] = "DELETE";
  HttpMethod[HttpMethod["HEAD"] = 5] = "HEAD";
})(HttpMethod || (HttpMethod = {}));
class AlertModalService {
  constructor() {
    this.visibility = false;
    this.message = "";
    this.type = AlertType.Success;
  }
  get AlertType() {
    return AlertType;
  }
  show(message, duration = 8000, type = AlertType.Error) {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }
    this.visibility = true;
    this.message = message;
    this.type = type;
    if (duration > 0) {
      this.timeout = setTimeout(() => {
        this.visibility = false;
      }, duration);
    }
  }
  static {
    this.ɵfac = function AlertModalService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AlertModalService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AlertModalService,
      factory: AlertModalService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AlertModalService, [{
    type: Injectable
  }], null, null);
})();
var AlertType;
(function (AlertType) {
  AlertType[AlertType["Success"] = 0] = "Success";
  AlertType[AlertType["Error"] = 1] = "Error";
})(AlertType || (AlertType = {}));
class AdminService {
  constructor(base) {
    this.base = base;
    //
  }
  getUsers() {
    return this.base.executeRequest('/manage/users', HttpMethod.GET);
  }
  runJob(path) {
    return this.base.executeRequest(`/job/${path}`, HttpMethod.POST);
  }
  getErrors() {
    return this.base.executeRequest('/manage/errors', HttpMethod.GET);
  }
  saveUser(user) {
    return this.base.executeRequest('/manage/users', HttpMethod.PUT, user);
  }
  deleteUser(user) {
    return this.base.executeRequest('/manage/user/' + user.id, HttpMethod.DELETE);
  }
  loginAs(user) {
    return this.base.executeRequest('/user/login', HttpMethod.POST, {
      id: user.user.id
    });
  }
  getHealthStatus() {
    return this.base.executeRequest('/admin/systems', HttpMethod.GET);
  }
  getStats(date, global = false) {
    return this.base.executeRequest(`/admin/stats?date=${date.toString()}&global=${global ? '1' : '0'}`, HttpMethod.GET);
  }
  static {
    this.ɵfac = function AdminService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AdminService)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AdminService,
      factory: AdminService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AdminService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }], null);
})();
class CryptoService {
  get publicKey() {
    return this.clientPubKey;
  }
  constructor(base) {
    this.base = base;
    if (base.allowLocalStorage) {
      this.setKeys();
    }
  }
  setKeys() {
    const clientPubKey = localStorage.getItem('RSA.PublicKey');
    const clientPrivateKey = localStorage.getItem('RSA.PrivateKey');
    if (clientPubKey && clientPrivateKey) {
      this.clientPubKey = clientPubKey;
      this.clientPrivateKey = clientPrivateKey;
    }
    if (this.clientPubKey == null || this.clientPrivateKey == null) {
      const pki = Forge.pki;
      const rsa = pki.rsa;
      const keyPair = rsa.generateKeyPair(2048);
      this.clientPubKey = pki.publicKeyToPem(keyPair.publicKey);
      this.clientPrivateKey = pki.privateKeyToPem(keyPair.privateKey);
      localStorage.setItem('RSA.PublicKey', this.clientPubKey);
      localStorage.setItem('RSA.PrivateKey', this.clientPrivateKey);
    }
  }
  getBackendPubKey() {
    return this.base.executeRequest('/publicKey', HttpMethod.GET);
  }
  getSignatureKey() {
    return this.base.executeRequest('/signature', HttpMethod.GET);
  }
  decryptSingleValue(valueToDecrypt) {
    const privateKey = Forge.pki.privateKeyFromPem(this.clientPrivateKey);
    return privateKey.decrypt(atob(valueToDecrypt));
  }
  async encryptSingleValue(valueToEncrypt) {
    try {
      const keyResult = await this.getBackendPubKey();
      const rsa = Forge.pki.publicKeyFromPem(keyResult.publicKey);
      return btoa(rsa.encrypt(valueToEncrypt));
    } catch (e) {
      throw e;
    }
  }
  async encryptMultipleValues(valuesToEncrypt) {
    try {
      const outputArr = [];
      const rsa = Forge.pki.publicKeyFromPem((await this.getBackendPubKey()).publicKey);
      valuesToEncrypt.forEach(value => {
        outputArr.push(btoa(rsa.encrypt(value)));
      });
      return outputArr;
    } catch (e) {
      throw e;
    }
  }
  static {
    this.ɵfac = function CryptoService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CryptoService)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CryptoService,
      factory: CryptoService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CryptoService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }], null);
})();
class AuthService {
  constructor(base, crypto) {
    this.base = base;
    this.crypto = crypto;
    // env.apiUrl
  }
  async login(login, password, stayLoggedIn = false) {
    return this.base.executeRequest('/auth/login', HttpMethod.POST, {
      ec: true,
      login: await this.crypto.encryptSingleValue(login),
      password: await this.crypto.encryptSingleValue(password),
      pubKey: this.crypto.clientPubKey,
      stayLoggedIn
    });
  }
  async register(email, password) {
    return this.base.executeRequest('/auth/register', HttpMethod.POST, {
      email,
      password
    });
  }
  confirmOTP(token) {
    return this.base.executeRequest('/otp', HttpMethod.POST, {
      token
    });
  }
  passwordResetRequest(email) {
    return this.base.executeRequest('/auth/password/reset-request', HttpMethod.POST, {
      email
    });
  }
  passwordReset(email, code, password) {
    return this.base.executeRequest('/auth/password/reset', HttpMethod.POST, {
      email,
      code,
      password
    });
  }
  enableOTP(otpCode, password) {
    return this.base.executeRequest('/otp/enable', HttpMethod.POST, {
      otpCode,
      password
    });
  }
  disableOTP(password) {
    return this.base.executeRequest('/otp/disable', HttpMethod.POST, {
      password
    });
  }
  getCurrentUserDetails() {
    return this.base.executeRequest('/auth/user', HttpMethod.GET);
  }
  checkSession() {
    return this.base.executeRequest('/auth/session', HttpMethod.GET, {}, false, false, undefined, false);
  }
  logout() {
    return this.base.executeRequest('/auth/logout', HttpMethod.POST);
  }
  requestVerify(method = 'email') {
    return this.base.executeRequest('/session/verify/request', HttpMethod.POST, {
      method
    });
  }
  verifySession(token) {
    return this.base.executeRequest('/session/verify', HttpMethod.POST, {
      token
    });
  }
  requestLoginWithMail(email, isAdminClient = false) {
    return this.base.executeRequest(`/auth/login/mail/request?admin=${isAdminClient ? '1' : '0'}`, HttpMethod.POST, {
      email
    });
  }
  confirmLoginWithMail(secret, stayLoggedIn = false) {
    return this.base.executeRequest('/auth/login/mail/confirm', HttpMethod.POST, {
      secret,
      stayLoggedIn
    });
  }
  savePubKey(pubKey) {
    return this.base.executeRequest('/auth/pubKey', HttpMethod.PATCH, {
      pubKey
    });
  }
  isPubKeySaved() {
    return this.base.executeRequest('/auth/pubKey', HttpMethod.HEAD, {}, false, false, undefined, false);
  }
  getAuthMethods(email) {
    return this.base.executeRequest(`/auth/methods/${email}`, HttpMethod.GET);
  }
  findAccount(email) {
    return this.base.executeRequest(`/auth/find/${email}`, HttpMethod.GET);
  }
  static {
    this.ɵfac = function AuthService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AuthService)(i0.ɵɵinject(BaseService), i0.ɵɵinject(CryptoService));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AuthService
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthService, [{
    type: NgModule
  }], () => [{
    type: BaseService
  }, {
    type: CryptoService
  }], null);
})();
class InfoService {
  constructor(base) {
    this.base = base;
    //
  }
  async getInfo() {
    return await this.base.executeRequest(`/test?${this.base.generateUniqueId()}`, HttpMethod.GET);
  }
  async checkHealth() {
    return await this.base.executeRequest('/health', HttpMethod.GET);
  }
  async sendContactForm(name, email, text) {
    return await this.base.executeRequest('/contact', HttpMethod.POST, {
      name,
      email,
      text
    });
  }
  static {
    this.ɵfac = function InfoService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || InfoService)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: InfoService,
      factory: InfoService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InfoService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }], null);
})();
class CmsService {
  constructor(base, infoService) {
    this.base = base;
    this.infoService = infoService;
    //
  }
  async getUniqueIdOrEmpty() {
    const info = await this.infoService.getInfo();
    return info.cachingEnabled ? `?${this.base.generateUniqueId()}` : '';
  }
  async getSections() {
    const path = `/portfolio/sections${await this.getUniqueIdOrEmpty()}`;
    return await this.base.executeRequest(path, HttpMethod.GET);
  }
  async getSection(sectionId) {
    const path = `/portfolio/section/${sectionId}${await this.getUniqueIdOrEmpty()}`;
    return await this.base.executeRequest(path, HttpMethod.GET);
  }
  async updateSection(section) {
    const path = `/portfolio/section${await this.getUniqueIdOrEmpty()}`;
    return await this.base.executeRequest(path, HttpMethod.PATCH, section);
  }
  async getFaqQuestions() {
    const path = `/faq/questions${await this.getUniqueIdOrEmpty()}`;
    return await this.base.executeRequest(path, HttpMethod.GET);
  }
  async createFaqQuestion(question) {
    const path = `/faq/question${await this.getUniqueIdOrEmpty()}`;
    return await this.base.executeRequest(path, HttpMethod.POST, question);
  }
  async updateFaqQuestion(question) {
    const path = `/faq/question${await this.getUniqueIdOrEmpty()}`;
    return await this.base.executeRequest(path, HttpMethod.PATCH, question);
  }
  async deleteFaqQuestion(questionId) {
    const path = `/faq/question/${questionId}${await this.getUniqueIdOrEmpty()}`;
    return await this.base.executeRequest(path, HttpMethod.DELETE);
  }
  static {
    this.ɵfac = function CmsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CmsService)(i0.ɵɵinject(BaseService), i0.ɵɵinject(InfoService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CmsService,
      factory: CmsService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CmsService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }, {
    type: InfoService
  }], null);
})();
class CrewService {
  constructor(base) {
    this.base = base;
    //
  }
  async getCrews() {
    return await this.base.executeRequest('/crews', HttpMethod.GET);
  }
  async getCrew(id) {
    return await this.base.executeRequest(`/crew/${id}`, HttpMethod.GET);
  }
  async deleteCrew(id) {
    return await this.base.executeRequest(`/crew/${id}`, HttpMethod.DELETE);
  }
  async deleteAssignmentOfCrewMember(crewId, crewMemberId, type) {
    return await this.base.executeRequest(`/crew/${crewId}/${crewMemberId}?type=${type}`, HttpMethod.DELETE);
  }
  async patchCrew(id, crew) {
    return await this.base.executeRequest(`/crew/${id}`, HttpMethod.PATCH, crew);
  }
  async postCrew(crew) {
    return await this.base.executeRequest('/crew', HttpMethod.POST, crew);
  }
  async getCrewMembers() {
    return await this.base.executeRequest('/crew/members', HttpMethod.GET, {}, false, false, undefined, false);
  }
  async getCrewMember(id) {
    return await this.base.executeRequest(`/crew/member/${id}`, HttpMethod.GET);
  }
  async deleteCrewMember(id) {
    return await this.base.executeRequest(`/crew/member/${id}`, HttpMethod.DELETE);
  }
  async patchCrewMember(id, crewMember) {
    return await this.base.executeRequest(`/crew/member/${id}`, HttpMethod.PATCH, crewMember);
  }
  async postCrewMember(crewMember) {
    return await this.base.executeRequest('/crew/member', HttpMethod.POST, crewMember);
  }
  static {
    this.ɵfac = function CrewService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrewService)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrewService,
      factory: CrewService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrewService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }], null);
})();
class ExternalService {
  constructor(base) {
    this.base = base;
    //
  }
  async getCountries(search) {
    return await this.base.executeRequest(`https://restcountries.com/v3.1/translation/${search}`, HttpMethod.GET);
  }
  static {
    this.ɵfac = function ExternalService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ExternalService)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ExternalService,
      factory: ExternalService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ExternalService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }], null);
})();
var SignalKIdentifier;
(function (SignalKIdentifier) {
  SignalKIdentifier["electricalAlternators0RevolutionsValue"] = "electrical.alternators.0.revolutions";
  SignalKIdentifier["electricalBatteriesMainLifetimeDischargeValue"] = "electrical.batteries.main.lifetimeDischarge";
  SignalKIdentifier["electricalBatteriesMainVoltageValue"] = "electrical.batteries.main.voltage";
  SignalKIdentifier["electricalBatteriesMainCurrentValue"] = "electrical.batteries.main.current";
  SignalKIdentifier["electricalBatteriesMainCapacityConsumedChargeValue"] = "electrical.batteries.main.capacity.consumedCharge";
  SignalKIdentifier["electricalBatteriesMainCapacityStateOfChargeValue"] = "electrical.batteries.main.capacity.stateOfCharge";
  SignalKIdentifier["electricalBatteriesMainCapacityTimeRemainingValue"] = "electrical.batteries.main.capacity.timeRemaining";
  SignalKIdentifier["navigationPositionMetaPropertiesLongitudeExample"] = "navigation.position.meta.properties.longitude.example";
  SignalKIdentifier["navigationPositionMetaPropertiesLatitudeExample"] = "navigation.position.meta.properties.latitude.example";
  SignalKIdentifier["navigationPositionValueLongitude"] = "navigation.position.longitude";
  SignalKIdentifier["navigationPositionValueLatitude"] = "navigation.position.latitude";
  SignalKIdentifier["navigationGnssSatellitesValue"] = "navigation.gnss.satellites";
  SignalKIdentifier["navigationGnssAntennaAltitudeValue"] = "navigation.gnss.antennaAltitude";
  SignalKIdentifier["navigationGnssHorizontalDilutionValue"] = "navigation.gnss.horizontalDilution";
  SignalKIdentifier["navigationGnssDifferentialAgeValue"] = "navigation.gnss.differentialAge";
  SignalKIdentifier["navigationGnssDifferentialReferenceValue"] = "navigation.gnss.differentialReference";
  SignalKIdentifier["navigationGnssSatellitesInViewValueCount"] = "navigation.gnss.satellitesInView.value.count";
  SignalKIdentifier["navigationGnssSatellitesInViewValueSatellites0Id"] = "navigation.gnss.satellitesInView.value.satellites.0.id";
  SignalKIdentifier["navigationGnssSatellitesInViewValueSatellites0Elevation"] = "navigation.gnss.satellitesInView.value.satellites.0.elevation";
  SignalKIdentifier["navigationGnssSatellitesInViewValueSatellites0Azimuth"] = "navigation.gnss.satellitesInView.value.satellites.0.azimuth";
  SignalKIdentifier["navigationGnssSatellitesInViewValueSatellites0SNR"] = "navigation.gnss.satellitesInView.value.satellites.0.SNR";
  SignalKIdentifier["navigationSpeedOverGround"] = "navigation.speedOverGround";
  SignalKIdentifier["navigationCourseOverGroundTrue"] = "navigation.courseOverGroundTrue";
  SignalKIdentifier["navigationCourseOverGroundMagnetic"] = "navigation.courseOverGroundMagnetic";
  SignalKIdentifier["environmentOutsideTemperature"] = "environment.outside.temperature";
  SignalKIdentifier["environmentWindAngleApparent"] = "environment.wind.angleApparent";
  SignalKIdentifier["environmentWindSpeedApparent"] = "environment.wind.speedApparent";
})(SignalKIdentifier || (SignalKIdentifier = {}));
class TracksService {
  constructor(base) {
    this.base = base;
    //
  }
  async getTracks(page, take) {
    return await this.base.executeRequest(`/tracks/${page}/${take}`, HttpMethod.GET);
  }
  async getTrack(id, receiveRecords = false) {
    return await this.base.executeRequest(`/track/${id}?sendData=${receiveRecords.toString()}`, HttpMethod.GET);
  }
  async getTrackDataKeys() {
    return await this.base.executeRequest('/trackDataKeys', HttpMethod.GET);
  }
  async updateTrackDataKey(key) {
    return await this.base.executeRequest('/trackDataKey', HttpMethod.PUT, key);
  }
  async getTrackDataUnits() {
    return await this.base.executeRequest('/track/data/units', HttpMethod.GET);
  }
  async putTrack(track) {
    const copyOfTrack = Object.assign({}, track);
    copyOfTrack.records = [];
    return await this.base.executeRequest('/track', HttpMethod.PUT, copyOfTrack);
  }
  async getStats(id) {
    return await this.base.executeRequest(`/track/${id}/stats`, HttpMethod.GET);
  }
  async getRecord(id) {
    return await this.base.executeRequest(`/record/${id}`, HttpMethod.GET);
  }
  async deleteTrack(id) {
    return await this.base.executeRequest(`/track/${id}`, HttpMethod.DELETE);
  }
  async getDataByKey(dataKey, record) {
    return await this.base.executeRequest(`/track/data/${record.id}/${dataKey.identifier}`, HttpMethod.GET);
  }
  async setPermalink(journalId, permalink = null) {
    return await this.base.executeRequest(`/journal/link/${journalId}`, HttpMethod.PATCH, {
      permalink
    });
  }
  async deletePermalink(journalId) {
    return await this.base.executeRequest(`/journal/link/${journalId}`, HttpMethod.DELETE);
  }
  static {
    this.ɵfac = function TracksService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TracksService)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TracksService,
      factory: TracksService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TracksService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }], null);
})();
class ExtractDataService {
  constructor(trackService) {
    this.trackService = trackService;
    this.trackDataKeys = [];
    this.trackDataUnits = [];
    //
  }
  async init() {
    if (this.trackDataKeys.length < 1) {
      this.trackDataKeys = await this.trackService.getTrackDataKeys();
    }
    if (this.trackDataUnits.length < 1) {
      this.trackDataUnits = await this.trackService.getTrackDataUnits();
    }
  }
  getData(data, identifierKey) {
    if (data == null) {
      return 0;
    }
    const key = this.trackDataKeys.find(x => x.identifier === identifierKey);
    if (key == null) {
      return 0;
    }
    const item = data.find(d => d.identifierId === key.id)?.value;
    if (item != null) {
      return item;
    }
    return 0;
  }
  getLatitude(data) {
    return this.getData(data, SignalKIdentifier.navigationPositionValueLatitude);
  }
  getLongitude(data) {
    return this.getData(data, SignalKIdentifier.navigationPositionValueLongitude);
  }
  getCourse(data) {
    return this.getData(data, SignalKIdentifier.navigationCourseOverGroundTrue);
  }
  getSpeed(data) {
    return this.getData(data, SignalKIdentifier.navigationSpeedOverGround);
  }
  getWindAngle(data) {
    return this.getData(data, SignalKIdentifier.environmentWindAngleApparent);
  }
  getWindSpeed(data) {
    return this.getData(data, SignalKIdentifier.environmentWindSpeedApparent);
  }
  getOutsideTemperature(data) {
    return this.getData(data, SignalKIdentifier.environmentOutsideTemperature);
  }
  getFrequencyTerminalW(data) {
    return this.getData(data, SignalKIdentifier.electricalAlternators0RevolutionsValue);
  }
  static {
    this.ɵfac = function ExtractDataService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ExtractDataService)(i0.ɵɵinject(TracksService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ExtractDataService,
      factory: ExtractDataService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ExtractDataService, [{
    type: Injectable
  }], () => [{
    type: TracksService
  }], null);
})();
class GatewayService {
  constructor(base) {
    this.base = base;
    //
  }
  async getGateways() {
    return await this.base.executeRequest('/gateways', HttpMethod.GET);
  }
  async saveGateway(gateway) {
    return await this.base.executeRequest('/gateway', HttpMethod.PUT, gateway);
  }
  static {
    this.ɵfac = function GatewayService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GatewayService)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: GatewayService,
      factory: GatewayService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GatewayService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }], null);
})();
class IssueService {
  constructor(base) {
    this.base = base;
    this.visible = false;
    //
  }
  async postIssue(title, description) {
    return await this.base.executeRequest('/issue', HttpMethod.POST, {
      title,
      description
    });
  }
  static {
    this.ɵfac = function IssueService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || IssueService)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: IssueService,
      factory: IssueService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IssueService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }], null);
})();
var AssetType;
(function (AssetType) {
  AssetType[AssetType["Image"] = 0] = "Image";
  AssetType[AssetType["Video"] = 1] = "Video";
})(AssetType || (AssetType = {}));
class Journal {
  constructor() {
    this.tracks = [];
    this.readonly = false;
  }
}
class JournalHelper {
  createUpdateDto(journal) {
    const journalCopy = _.cloneDeep(journal);
    journalCopy.journals.forEach(entity => entity.assets = []);
    journalCopy.journals.forEach(entity => entity.galleryImages = []);
    return Object.assign(new Journal(), journalCopy);
  }
  static {
    this.ɵfac = function JournalHelper_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || JournalHelper)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: JournalHelper,
      factory: JournalHelper.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(JournalHelper, [{
    type: Injectable
  }], null, null);
})();
class JournalService {
  constructor(base, http, journalHelper) {
    this.base = base;
    this.http = http;
    this.journalHelper = journalHelper;
    this.progress = new Subject();
    //
  }
  async getJournals() {
    return await this.base.executeRequest('/journals', HttpMethod.GET);
  }
  async getJournal(id) {
    return await this.base.executeRequest(`/journal/${id}`, HttpMethod.GET);
  }
  async getJournalTracks(journal) {
    return await this.base.executeRequest(`/journal/tracks/${journal.id}`, HttpMethod.GET);
  }
  async getJournalAsUser(id, authToken) {
    return await this.base.executeRequest(`/journal/${id}?auth=${authToken}`, HttpMethod.GET);
  }
  async getJournalByLink(permalink) {
    return await this.base.executeRequest(`/journal/link/${permalink}`, HttpMethod.GET);
  }
  async getStats(id, trackId) {
    return await this.base.executeRequest(`/journal/${id}/stats?track=${trackId}`, HttpMethod.GET);
  }
  async postJournal(journal) {
    return (await this.base.executeRequest('/journal', HttpMethod.POST, journal)).id;
  }
  async putJournal(journal) {
    return await this.base.executeRequest('/journal', HttpMethod.PUT, this.journalHelper.createUpdateDto(journal));
  }
  async deleteJournal(journalId) {
    return await this.base.executeRequest(`/journal/${journalId}`, HttpMethod.DELETE);
  }
  async deleteAssetsByJournal(journalId, assetType = undefined) {
    const path = assetType !== undefined ? `/journal/${journalId}/assets?type=${assetType == AssetType.Image ? 'image' : 'video'}` : `/journal/${journalId}/assets`;
    return await this.base.executeRequest(path, HttpMethod.DELETE);
  }
  async postAsset(journalId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return new Promise((resolve, reject) => {
      this.http.post(`${this.base.url}/journal/asset/${journalId}`, formData, {
        ...this.base.httpOptions,
        reportProgress: true,
        observe: 'events'
      }).pipe(catchError(err => {
        this.base.handleError(err);
        reject();
        return EMPTY;
      })).subscribe(event => {
        switch (event.type) {
          case HttpEventType.Sent:
            return `Uploading file "${file.name}" of size ${file.size}.`;
          case HttpEventType.UploadProgress:
            this.base.progress = event.total ? Math.round(100 * event.loaded / event.total) : 0;
            this.progress.next(this.base.progress);
            return `File "${file.name}" is ${this.base.progress}% uploaded.`;
          case HttpEventType.Response:
            this.base.progress = -1;
            resolve(true);
            return `File "${file.name}" was completely uploaded!`;
          default:
            return `File "${file.name}" surprising upload event: ${event.type}.`;
        }
      });
    });
  }
  async getAsset(assetId, quality = "sm") {
    return await this.base.executeRequest(`/journal/asset/${assetId}?quality=${quality}`, HttpMethod.GET, {}, false, false, 'blob');
  }
  async deleteAsset(assetId) {
    return await this.base.executeRequest(`/journal/asset/${assetId}`, HttpMethod.DELETE);
  }
  async getAssetsByEntity(entityId) {
    return await this.base.executeRequest(`/journal/entity/${entityId}/assets`, HttpMethod.GET);
  }
  async deleteEntity(entityId) {
    return await this.base.executeRequest(`/journal/entity/${entityId}`, HttpMethod.DELETE);
  }
  async getAppSecret(journalId) {
    return await this.base.executeRequest(`/app/secret/${journalId}`, HttpMethod.GET);
  }
  async processImageResolutions(journalId) {
    return await this.base.executeRequest(`/job/images/journal`, HttpMethod.POST, {
      id: journalId
    });
  }
  async postThumbnail(assetId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return new Promise((resolve, reject) => {
      this.http.post(`${this.base.url}/journal/asset/thumbnail/${assetId}`, formData, {
        ...this.base.httpOptions,
        reportProgress: true,
        observe: 'events'
      }).pipe(catchError(err => {
        this.base.handleError(err);
        reject();
        return EMPTY;
      })).subscribe(event => {
        switch (event.type) {
          case HttpEventType.Sent:
            return `Uploading file "${file.name}" of size ${file.size}.`;
          case HttpEventType.UploadProgress:
            this.base.progress = event.total ? Math.round(100 * event.loaded / event.total) : 0;
            this.progress.next(this.base.progress);
            return `File "${file.name}" is ${this.base.progress}% uploaded.`;
          case HttpEventType.Response:
            this.base.progress = -1;
            resolve(true);
            return `File "${file.name}" was completely uploaded!`;
          default:
            return `File "${file.name}" surprising upload event: ${event.type}.`;
        }
      });
    });
  }
  async deleteThumbnail(assetId) {
    return await this.base.executeRequest(`/journal/asset/thumbnail/${assetId}`, HttpMethod.DELETE);
  }
  async shareJournal(shareObject) {
    return await this.base.executeRequest('/journal/share', HttpMethod.POST, shareObject);
  }
  static {
    this.ɵfac = function JournalService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || JournalService)(i0.ɵɵinject(BaseService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(JournalHelper));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: JournalService,
      factory: JournalService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(JournalService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }, {
    type: i2.HttpClient
  }, {
    type: JournalHelper
  }], null);
})();
class MapKitService {
  constructor(base, cryptoService, authService) {
    this.base = base;
    this.cryptoService = cryptoService;
    this.authService = authService;
    //
  }
  async getDetails() {
    await this.ensurePublicKey();
    return await this.base.executeRequest('/map/details', HttpMethod.GET);
  }
  async getToken() {
    await this.ensurePublicKey();
    return await this.base.executeRequest('/mapkit/token', HttpMethod.GET);
  }
  async ensurePublicKey() {
    try {
      await this.authService.isPubKeySaved();
    } catch (e) {
      this.cryptoService.setKeys();
      while (!this.cryptoService.clientPubKey) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      await this.authService.savePubKey(this.cryptoService.clientPubKey);
    }
  }
  static {
    this.ɵfac = function MapKitService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MapKitService)(i0.ɵɵinject(BaseService), i0.ɵɵinject(CryptoService), i0.ɵɵinject(AuthService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MapKitService,
      factory: MapKitService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MapKitService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }, {
    type: CryptoService
  }, {
    type: AuthService
  }], null);
})();
class MembershipService {
  constructor(base) {
    this.base = base;
    //
  }
  async getMemberships() {
    return await this.base.executeRequest(`/memberships`, HttpMethod.GET);
  }
  async getOwnMembership() {
    return await this.base.executeRequest(`/membership`, HttpMethod.GET);
  }
  async getActiveMemberships() {
    return await this.base.executeRequest(`/memberships/active`, HttpMethod.GET, {}, false, false, undefined, false);
  }
  async hasActiveMembership(user) {
    return (await this.base.executeRequest(`/memberships/${user.id}/isActive`, HttpMethod.GET)).state;
  }
  async getActiveMembership(user) {
    return await this.base.executeRequest(`/membership/${user.id}`, HttpMethod.GET);
  }
  async addMembership(user, membership, expiredAt = null) {
    return await this.base.executeRequest(`/membership/add`, HttpMethod.POST, {
      user: user.id,
      membership: membership.id,
      expiredAt
    });
  }
  async revokeMembership(user) {
    return await this.base.executeRequest(`/membership/revoke`, HttpMethod.POST, {
      user: user.id
    });
  }
  async updateMembershipProperties(memberships) {
    return await this.base.executeRequest(`/memberships`, HttpMethod.PATCH, memberships);
  }
  static {
    this.ɵfac = function MembershipService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MembershipService)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MembershipService,
      factory: MembershipService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MembershipService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }], null);
})();
class PaymentService {
  constructor(base) {
    this.base = base;
    //
  }
  async initMembershipPayment(membershipId) {
    return await this.base.executeRequest(`/membership/${membershipId}/pay`, HttpMethod.GET);
  }
  static {
    this.ɵfac = function PaymentService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PaymentService)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PaymentService,
      factory: PaymentService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PaymentService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }], null);
})();
var PermissionType;
(function (PermissionType) {
  PermissionType["Administrator"] = "*";
  PermissionType["Permission"] = "permissions";
  PermissionType["User"] = "users";
  PermissionType["Settings"] = "settings";
  PermissionType["OpenAiUsage"] = "openaiusage";
  PermissionType["Devices"] = "devices";
  PermissionType["Gateways"] = "gateways";
  PermissionType["TrackDataKeys"] = "track_data_keys";
  PermissionType["PortfolioManagement"] = "portfolio_management";
})(PermissionType || (PermissionType = {}));
class PermissionService {
  constructor(base) {
    this.base = base;
    //
  }
  async findPermission(identifier) {
    return (await this.getPermissionAssignments()).find(x => x.permission?.identifier == identifier);
  }
  getPermissionAssignments() {
    return this.base.executeRequest('/user/permissions', HttpMethod.GET);
  }
  getPermissions() {
    return this.base.executeRequest('/permissions', HttpMethod.GET);
  }
  getPermissionsByUserId(userId) {
    return this.base.executeRequest('/permissions/user/' + userId, HttpMethod.GET);
  }
  assignPermissionToUser(user_id, permission_id, writeable = false) {
    return this.base.executeRequest('/permissions/user', HttpMethod.POST, {
      user_id,
      permission_id,
      writeable
    });
  }
  revokePermission(permission_assignment_id) {
    return this.base.executeRequest('/permission/assignment/' + permission_assignment_id, HttpMethod.DELETE);
  }
  async checkReadable(permissionType, tryAsAdmin = true) {
    try {
      const permission = await this.findPermission(permissionType);
      return permission != undefined || tryAsAdmin && (await this.isAdmin());
    } catch (e) {
      return false;
    }
  }
  async checkWriteable(permissionType) {
    try {
      const isAdmin = await this.isAdmin();
      const permission = await this.findPermission(permissionType);
      if (permission != undefined && permission.writeable != undefined || isAdmin) {
        return isAdmin ?? permission?.writeable;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
  async isAdmin() {
    return await this.checkReadable(PermissionType.Administrator, false);
  }
  static {
    this.ɵfac = function PermissionService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PermissionService)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PermissionService
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PermissionService, [{
    type: NgModule
  }], () => [{
    type: BaseService
  }], null);
})();
class UserDetailsService {
  constructor(base) {
    this.base = base;
    //
  }
  async getUserDetails() {
    return await this.base.executeRequest(`/user/details`, HttpMethod.GET);
  }
  async putUserDetails(user) {
    return await this.base.executeRequest(`/user/details`, HttpMethod.PUT, user);
  }
  async requestPhoneVerify() {
    return await this.base.executeRequest(`/user/phone/request-verification`, HttpMethod.POST);
  }
  async confirmPhoneVerify(code) {
    return await this.base.executeRequest(`/user/phone/verify`, HttpMethod.POST, {
      code
    });
  }
  async deletePhone(code) {
    return await this.base.executeRequest(`/user/phone/${code}`, HttpMethod.DELETE);
  }
  static {
    this.ɵfac = function UserDetailsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || UserDetailsService)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserDetailsService,
      factory: UserDetailsService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserDetailsService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }], null);
})();
class UserService {
  constructor(base) {
    this.base = base;
    // httpService.url
  }
  hasPermissions() {
    return this.base.executeRequest('/admin', HttpMethod.HEAD);
  }
  changePassword(password) {
    return this.base.executeRequest('/password', HttpMethod.PUT, {
      password
    });
  }
  changeMail(email) {
    return this.base.executeRequest('/email', HttpMethod.PUT, {
      email
    });
  }
  confirmMail(token) {
    return this.base.executeRequest('/email', HttpMethod.POST, {
      token
    });
  }
  getUsers() {
    return this.base.executeRequest('/users', HttpMethod.GET);
  }
  changeUserdataOfCurrentUser(obj) {
    return this.base.executeRequest('/userdata', HttpMethod.PUT, obj);
  }
  changeUsername(username) {
    return this.base.executeRequest('/username', HttpMethod.PUT, {
      username
    });
  }
  changeUserdataAsAdmin(obj) {
    return this.base.executeRequest('/user', HttpMethod.PUT, obj);
  }
  static {
    this.ɵfac = function UserService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || UserService)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: UserService
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserService, [{
    type: NgModule
  }], () => [{
    type: BaseService
  }], null);
})();
class StatsService {
  constructor(base) {
    this.base = base;
    //
  }
  async getStats() {
    return await this.base.executeRequest(`/stats`, HttpMethod.GET, {}, false, false, undefined, false);
  }
  static {
    this.ɵfac = function StatsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || StatsService)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: StatsService,
      factory: StatsService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StatsService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }], null);
})();
class WebAuthnService {
  constructor(base, cryptoService) {
    this.base = base;
    this.cryptoService = cryptoService;
    //
  }
  getChallenge() {
    return this.base.executeRequest('/fido2/challenge', HttpMethod.GET);
  }
  getAuthChallenge(email) {
    return this.base.executeRequest('/auth/fido2/auth/challenge/' + email, HttpMethod.GET);
  }
  verifyRegister(regResp) {
    return this.base.executeRequest('/fido2/verify/register', HttpMethod.POST, regResp);
  }
  verifyAuth(regResp) {
    return this.base.executeRequest('/auth/fido2/auth', HttpMethod.POST, {
      ...regResp,
      pubKey: this.cryptoService.publicKey
    });
  }
  async registerClient() {
    const challenge = await this.getChallenge();
    if (challenge == null) {
      throw new Error('Registration of Client failed!');
    }
    const attResp = await startRegistration(challenge);
    await this.verifyRegister(attResp);
  }
  async getRegistrations() {
    return this.base.executeRequest('/fido2/registrations', HttpMethod.GET);
  }
  async updateRegistration(key) {
    return this.base.executeRequest('/fido2/registrations/' + key.id, HttpMethod.PATCH, key);
  }
  async deleteRegistration(key) {
    return this.base.executeRequest('/fido2/registrations/' + key.id, HttpMethod.DELETE);
  }
  async authClient(email) {
    const challenge = await this.getAuthChallenge(email);
    if (challenge == null) {
      return false;
    }
    try {
      const authQuery = await startAuthentication(challenge, false);
      const session = await this.verifyAuth(authQuery);
      if (session == null) {
        return false;
      }
      localStorage.setItem('sessionID', session.secret);
      return true;
    } catch (error) {
      console.error('Error while authenticating with FIDO2:', error);
      return false;
    }
  }
  static {
    this.ɵfac = function WebAuthnService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || WebAuthnService)(i0.ɵɵinject(BaseService), i0.ɵɵinject(CryptoService));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: WebAuthnService
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WebAuthnService, [{
    type: NgModule
  }], () => [{
    type: BaseService
  }, {
    type: CryptoService
  }], null);
})();
class SettingsService {
  constructor(base) {
    this.base = base;
    //
  }
  getSetupItems() {
    return this.base.executeRequest('/admin/setup/items', HttpMethod.GET);
  }
  saveSetupItem(key, value, type) {
    return this.base.executeRequest('/admin/setup/item', HttpMethod.PATCH, {
      key,
      value,
      type
    });
  }
  static {
    this.ɵfac = function SettingsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SettingsService)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SettingsService
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SettingsService, [{
    type: NgModule
  }], () => [{
    type: BaseService
  }], null);
})();
class RegistryService {
  constructor(journal, userDetails, auth, user, permission, issue, stats, fido2, admin, gateway, tracks, info, membership, mapKit, external, payment, settings, crewService, cms) {
    this.journal = journal;
    this.userDetails = userDetails;
    this.auth = auth;
    this.user = user;
    this.permission = permission;
    this.issue = issue;
    this.stats = stats;
    this.fido2 = fido2;
    this.admin = admin;
    this.gateway = gateway;
    this.tracks = tracks;
    this.info = info;
    this.membership = membership;
    this.mapKit = mapKit;
    this.external = external;
    this.payment = payment;
    this.settings = settings;
    this.crewService = crewService;
    this.cms = cms;
    //
  }
  static {
    this.ɵfac = function RegistryService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || RegistryService)(i0.ɵɵinject(JournalService), i0.ɵɵinject(UserDetailsService), i0.ɵɵinject(AuthService), i0.ɵɵinject(UserService), i0.ɵɵinject(PermissionService), i0.ɵɵinject(IssueService), i0.ɵɵinject(StatsService), i0.ɵɵinject(WebAuthnService), i0.ɵɵinject(AdminService), i0.ɵɵinject(GatewayService), i0.ɵɵinject(TracksService), i0.ɵɵinject(InfoService), i0.ɵɵinject(MembershipService), i0.ɵɵinject(MapKitService), i0.ɵɵinject(ExternalService), i0.ɵɵinject(PaymentService), i0.ɵɵinject(SettingsService), i0.ɵɵinject(CrewService), i0.ɵɵinject(CmsService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: RegistryService,
      factory: RegistryService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RegistryService, [{
    type: Injectable
  }], () => [{
    type: JournalService
  }, {
    type: UserDetailsService
  }, {
    type: AuthService
  }, {
    type: UserService
  }, {
    type: PermissionService
  }, {
    type: IssueService
  }, {
    type: StatsService
  }, {
    type: WebAuthnService
  }, {
    type: AdminService
  }, {
    type: GatewayService
  }, {
    type: TracksService
  }, {
    type: InfoService
  }, {
    type: MembershipService
  }, {
    type: MapKitService
  }, {
    type: ExternalService
  }, {
    type: PaymentService
  }, {
    type: SettingsService
  }, {
    type: CrewService
  }, {
    type: CmsService
  }], null);
})();
class SocketService {
  constructor(base, allowLocalStorage, authSocketConnection) {
    this.base = base;
    this.authSocketConnection = authSocketConnection;
    this.state = false;
    if (allowLocalStorage) {
      this.setupSocketConnection();
    }
  }
  setupSocketConnection() {
    this.client = io(`${this.base.host}`);
    this.client.on('connect', () => {
      console.log('Die Socket-Verbindung wurde hergestellt!');
      if (this.client.id !== undefined) {
        sessionStorage.setItem('Socket-ID', this.client.id);
      }
      if (this.authSocketConnection) {
        this.client.emit('auth', this.base.getAuthorizationToken());
      }
    });
    this.client.on('auth:ok', () => {
      console.log('Die Authentifizierung der Socket-Verbindung war erfolgreich!');
      this.state = true;
    });
    this.client.on('disconnect', () => {
      console.log('Die Socket-Verbindung wurde unterbrochen!');
      this.state = false;
    });
  }
  static {
    this.ɵfac = function SocketService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SocketService)(i0.ɵɵinject(BaseService), i0.ɵɵinject('allowLocalStorage'), i0.ɵɵinject('authSocketConnection'));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SocketService,
      factory: SocketService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SocketService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['allowLocalStorage']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['authSocketConnection']
    }]
  }], null);
})();
class JournalEntity {
  constructor() {
    this.assignments = [];
    // Non API properties
    this.assetsLoaded = false;
    this.isVisible = false;
    this.fullScreen = false;
    this.selectedAssetIndex = 0;
    this.galleryImages = [];
    this.displayMore = false;
  }
}
class DateHelper {
  constructor() {
    this.dateDiffDef = {
      millennium: 31536000000000,
      century: 3153600000000,
      decade: 315360000000,
      year: 31536000000,
      quarter: 7776000000,
      month: 2592000000,
      week: 604800000,
      day: 86400000,
      hour: 3600000,
      minute: 60000,
      second: 1000,
      millisecond: 1
    };
  }
  dateDiff(dateStart, dateEnd = new Date(), ...units) {
    if (typeof dateEnd === 'string') {
      dateEnd = new Date();
    }
    let delta = Math.abs(dateStart.getTime() - dateEnd.getTime());
    return (units.length ? units : Object.keys(this.dateDiffDef)).reduce((res, key) => {
      if (!this.dateDiffDef.hasOwnProperty(key)) {
        throw new Error('Unknown unit in dateDiff: ' + key);
      }
      // @ts-ignore
      res[key] = Math.floor(delta / dateDiffDef[key]);
      // @ts-ignore
      delta -= res[key] * dateDiffDef[key];
      return res;
    }, {});
  }
  convertToDate(timestamp) {
    if (timestamp != null) {
      const timestampArr = timestamp.split('T');
      const date = timestampArr[0];
      const dateArr = date.split('-');
      const year = dateArr[0];
      const month = dateArr[1];
      const day = dateArr[2];
      return day + '.' + month + '.' + year;
    } else {
      return null;
    }
  }
  convertToTime(timestamp, seconds = true) {
    const utc = +(localStorage.getItem('utc') ?? 0);
    if (timestamp != null) {
      const timestampArr = timestamp.split('T');
      let time = timestampArr[1];
      if (seconds) {
        return time.split('.')[0];
      } else {
        time = time.split('.')[0];
        const timeArr = time.split(':');
        let hour = +timeArr[0];
        hour = hour + utc;
        if (hour >= 24) {
          hour -= 24;
        }
        let hourString = hour.toString();
        if (hour < 10) {
          hourString = '0' + hourString;
        }
        return hourString + ':' + timeArr[1];
      }
    } else {
      return null;
    }
  }
  static stringToDate(dateString) {
    const parts = dateString.split('-');
    const year = +parts[0];
    const month = +parts[1] - 1;
    const day = +parts[2];
    const dateObject = new Date(year, month, day);
    return dateObject;
  }
  static generateRandomDate() {
    const start = new Date(2012, 0, 1);
    const end = new Date();
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  }
  static {
    this.ɵfac = function DateHelper_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DateHelper)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DateHelper,
      factory: DateHelper.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateHelper, [{
    type: Injectable
  }], null, null);
})();
class TestService {
  constructor(base, stringHelper, journalService) {
    this.base = base;
    this.stringHelper = stringHelper;
    this.journalService = journalService;
    //
  }
  async createDemoJournals(file) {
    for (let i = 0; i < 10; i++) {
      let journal = new Journal();
      journal.title = `Journal ${this.stringHelper.generateRandomString(4)}`;
      journal.revier = `Revier ${this.stringHelper.generateRandomString(4)}`;
      journal.boatType = `BoatType ${this.stringHelper.generateRandomString(4)}`;
      journal.boatName = `BoatName ${this.stringHelper.generateRandomString(4)}`;
      journal.miles = Math.floor(Math.random() * 1000);
      journal.country = await this.getRandomCountry();
      journal.year = Math.floor(Math.random() * 100) + 1900;
      const journalId = await this.journalService.postJournal(journal);
      console.log(`Created journal with ID ${journalId}`);
      journal.journals = [];
      journal.id = journalId;
      for (let j = 0; j < 1; j++) {
        const entity = new JournalEntity();
        entity.date = DateHelper.generateRandomDate();
        entity.text = this.stringHelper.generateRandomString(512);
        journal.journals.push(entity);
      }
      journal = await this.journalService.putJournal(journal);
      for (const entity of journal.journals) {
        console.log(`Created journal entity with ID ${entity.id}`);
        for (let k = 0; k < 1; k++) {
          await this.journalService.postAsset(entity.id, file);
          console.log(`Created asset for journal entity with ID ${entity.id}`);
        }
      }
    }
  }
  async getRandomCountry() {
    const result = await this.base.executeRequest(`https://restcountries.com/v3.1/all?fields=name,flags`, HttpMethod.GET);
    const countries = result.map(c => c.name.common);
    const randomIndex = Math.floor(Math.random() * countries.length);
    return countries[randomIndex];
  }
  static {
    this.ɵfac = function TestService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TestService)(i0.ɵɵinject(BaseService), i0.ɵɵinject(StringHelper), i0.ɵɵinject(JournalService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TestService,
      factory: TestService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TestService, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }, {
    type: StringHelper
  }, {
    type: JournalService
  }], null);
})();
class AssetHelper {
  constructor(baseService) {
    this.baseService = baseService;
    //
  }
  isImage(asset) {
    return asset.filename.toLowerCase().endsWith('.jpg') || asset.filename.toLowerCase().endsWith('.png') || asset.filename.toLowerCase().endsWith('.jpeg');
  }
  isVideo(asset) {
    return asset.filename.toLowerCase().endsWith('.mp4') || asset.filename.toLowerCase().endsWith('.mpeg') || asset.filename.toLowerCase().endsWith('.mov');
  }
  getAssetUrl(asset, quality = "sm") {
    return `${this.baseService.url}/journal/link/asset/${asset.secret}?quality=${quality}`;
  }
  static {
    this.ɵfac = function AssetHelper_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AssetHelper)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AssetHelper,
      factory: AssetHelper.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AssetHelper, [{
    type: Injectable
  }], () => [{
    type: BaseService
  }], null);
})();
class ClipboardHelper {
  fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      //
    }
    document.body.removeChild(textArea);
  }
  copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function () {}, function (err) {
      //
    });
  }
  static {
    this.ɵfac = function ClipboardHelper_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ClipboardHelper)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ClipboardHelper,
      factory: ClipboardHelper.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClipboardHelper, [{
    type: Injectable
  }], null, null);
})();
class NumericHelper {
  formatBytes(bytes) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let index = 0;
    let size = bytes;
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }
    return `${size.toFixed(2)} ${units[index]}`;
  }
  static {
    this.ɵfac = function NumericHelper_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NumericHelper)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NumericHelper,
      factory: NumericHelper.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumericHelper, [{
    type: Injectable
  }], null, null);
})();
class TimeoutHelper {
  wait(condition, timeout = 10) {
    return new Promise(async (resolve, reject) => {
      setTimeout(() => reject(), timeout * 1000);
      while (condition()) {
        await new Promise(r => setTimeout(() => r(), timeout * 200));
      }
      resolve();
    });
  }
  static {
    this.ɵfac = function TimeoutHelper_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TimeoutHelper)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TimeoutHelper,
      factory: TimeoutHelper.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TimeoutHelper, [{
    type: Injectable
  }], null, null);
})();
class ScrollService {
  constructor() {
    this.SCROLL_DURATION = 1200;
  }
  scrollToNextSection(currentElement) {
    const currentSection = currentElement.closest('section');
    if (currentSection) {
      const nextSection = currentSection.nextElementSibling;
      if (nextSection && nextSection.tagName.toLowerCase() === 'section') {
        const startPosition = window.pageYOffset;
        const targetPosition = nextSection.offsetTop;
        const distance = targetPosition - startPosition;
        this.smoothScrollTo(distance, this.SCROLL_DURATION);
      }
    }
  }
  scrollToSection(sectionId, duration = this.SCROLL_DURATION) {
    const targetSection = document.getElementById(sectionId);
    if (targetSection) {
      const startPosition = window.pageYOffset;
      const targetPosition = targetSection.offsetTop;
      const distance = targetPosition - startPosition;
      this.smoothScrollTo(distance, duration);
    } else {
      console.warn(`Section with id "${sectionId}" not found`);
    }
  }
  scrollToTop(duration = this.SCROLL_DURATION) {
    const startPosition = window.pageYOffset;
    const distance = -startPosition;
    this.smoothScrollTo(distance, duration);
  }
  isAtTop() {
    return window.pageYOffset <= 0;
  }
  smoothScrollTo(distance, duration) {
    const start = window.pageYOffset;
    const startTime = performance.now();
    const animateScroll = currentTime => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const easeInOutCubic = progress => {
        return progress < 0.5 ? 4 * progress * progress * progress : 1 - Math.pow(-2 * progress + 2, 3) / 2;
      };
      window.scrollTo({
        top: start + distance * easeInOutCubic(progress)
      });
      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };
    requestAnimationFrame(animateScroll);
  }
  static {
    this.ɵfac = function ScrollService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ScrollService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ScrollService,
      factory: ScrollService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScrollService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class AssetService {
  constructor(base) {
    this.base = base;
    this.progress = new Subject();
  }
  async createEmptyAssetObject(journalId, file) {
    const metadata = {
      journalEntityId: journalId,
      originalName: file.name
    };
    return this.base.executeRequest('/asset', HttpMethod.POST, metadata);
  }
  deleteAsset(asset) {
    return this.base.executeRequest(`/asset/${asset.id}`, HttpMethod.DELETE);
  }
  finalizeAsset(asset) {
    return this.base.executeRequest(`/asset/${asset.secret}/finalize`, HttpMethod.HEAD);
  }
  static {
    this.ɵfac = function AssetService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AssetService)(i0.ɵɵinject(BaseService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AssetService,
      factory: AssetService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AssetService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: BaseService
  }], null);
})();
const providers = [BaseService, AlertModalService, AdminService, AuthService, CmsService, CrewService, CryptoService, ExternalService, ExtractDataService, GatewayService, InfoService, IssueService, JournalService, MapKitService, MembershipService, PaymentService, PermissionService, RegistryService, SettingsService, SocketService, StatsService, TestService, TracksService, UserService, UserDetailsService, WebAuthnService, ScrollService, AssetService];
const helpers = [AssetHelper, ClipboardHelper, DateHelper, JournalHelper, NumericHelper, StringHelper, TimeoutHelper];
class ClientSdkModule {
  static forRoot(apiUrl, apiPath, allowLocalStorage = true, authSocketConnection = true, httpLogging = false) {
    return {
      ngModule: ClientSdkModule,
      providers: [{
        provide: 'apiUrl',
        useValue: apiUrl
      }, {
        provide: 'apiPath',
        useValue: apiPath
      }, {
        provide: 'allowLocalStorage',
        useValue: allowLocalStorage
      }, {
        provide: 'authSocketConnection',
        useValue: authSocketConnection
      }, {
        provide: 'httpLogging',
        useValue: httpLogging
      }, ...providers, ...helpers]
    };
  }
  static {
    this.ɵfac = function ClientSdkModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ClientSdkModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ClientSdkModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClientSdkModule, [{
    type: NgModule
  }], null, null);
})();
const provideClientSdk = (apiUrl, apiPath, allowLocalStorage = true, authSocketConnection = true, httpLogging = false) => {
  return [{
    provide: 'apiUrl',
    useValue: apiUrl
  }, {
    provide: 'apiPath',
    useValue: apiPath
  }, {
    provide: 'allowLocalStorage',
    useValue: allowLocalStorage
  }, {
    provide: 'authSocketConnection',
    useValue: authSocketConnection
  }, {
    provide: 'httpLogging',
    useValue: httpLogging
  }, ...providers, ...helpers];
};
const LogPrefixName = '[Logbuch.Client.SDK]:';
const LogPrefix = {
  I: `${LogPrefixName} Info:`,
  E: `${LogPrefixName} Error:`,
  W: `${LogPrefixName} Warn:`,
  D: `${LogPrefixName} Debug:`
};
class Coordinate {
  constructor(lon, lat) {
    this.lon = lon;
    this.lat = lat;
    //
  }
}
var DistanceUnit;
(function (DistanceUnit) {
  DistanceUnit["Kilometers"] = "km";
  DistanceUnit["Miles"] = "nm";
})(DistanceUnit || (DistanceUnit = {}));
class GalleryItem {
  constructor(entity, asset, itemImageSrc, thumbnailImageSrc, alt, title) {
    this.entity = entity;
    this.asset = asset;
    this.itemImageSrc = itemImageSrc;
    this.thumbnailImageSrc = thumbnailImageSrc;
    this.alt = alt;
    this.title = title;
  }
}
class JournalStats {
  constructor(startAt, endAt, tracksExist, distance, maxSpeed, speedAverage, windSpeedAverage, maxWindSpeed, minWindSpeed, maxBatteryCurrent, minBatteryCurrent, maxBatteryStateOfCharge, minBatteryStateOfCharge, temperatureAverage, maxTemperature, minTemperature, voiceEntries, manualEntries) {
    this.startAt = startAt;
    this.endAt = endAt;
    this.tracksExist = tracksExist;
    this.distance = distance;
    this.maxSpeed = maxSpeed;
    this.speedAverage = speedAverage;
    this.windSpeedAverage = windSpeedAverage;
    this.maxWindSpeed = maxWindSpeed;
    this.minWindSpeed = minWindSpeed;
    this.maxBatteryCurrent = maxBatteryCurrent;
    this.minBatteryCurrent = minBatteryCurrent;
    this.maxBatteryStateOfCharge = maxBatteryStateOfCharge;
    this.minBatteryStateOfCharge = minBatteryStateOfCharge;
    this.temperatureAverage = temperatureAverage;
    this.maxTemperature = maxTemperature;
    this.minTemperature = minTemperature;
    this.voiceEntries = voiceEntries;
    this.manualEntries = manualEntries;
    //
  }
}
var MapType;
(function (MapType) {
  MapType["Google"] = "google";
  MapType["AppleMaps"] = "apple";
})(MapType || (MapType = {}));
var SocketChannelEvents;
(function (SocketChannelEvents) {
  SocketChannelEvents["TrackRecords"] = "track:records";
})(SocketChannelEvents || (SocketChannelEvents = {}));
class BlacklistedIP {}
class Crew {}
class CrewMember {
  constructor() {
    this.imageUrl = 'assets/profile-user.svg';
  }
}
class FailedLogin {}
class FaqItem {}
class Gateway {
  constructor() {
    this.owner = null;
    this.isOwner = false;
    this.isMember = false;
  }
}
class GatewayIP {}
class JournalEntityAsset {
  constructor() {
    // Non API properties
    this.imageLoaded = false;
    this.largeImageLoaded = false;
    this.imageUrl = '';
    this.videoUrl = '';
  }
}
class ManualEntry {}
class PortfolioSection {}
class Track {}
class TrackData {}
class TrackDataKey {}
class TrackDataUnit {}
class TrackImportJob {}
class TrackRecord {}
class UserDetails {}
class VoiceEntry {}

/**
 * Generated bundle index. Do not edit.
 */

export { AdminService, AssetHelper, AssetService, AssetType, AuthService, BaseService, BlacklistedIP, ClientSdkModule, ClipboardHelper, CmsService, Coordinate, Crew, CrewMember, CrewService, CryptoService, DateHelper, DistanceUnit, ExternalService, ExtractDataService, FailedLogin, FaqItem, GalleryItem, Gateway, GatewayIP, GatewayService, HttpMethod, InfoService, IssueService, Journal, JournalEntity, JournalEntityAsset, JournalHelper, JournalService, JournalStats, LogPrefix, LogPrefixName, ManualEntry, MapKitService, MapType, MembershipService, NumericHelper, PaymentService, PermissionService, PermissionType, PortfolioSection, RegistryService, ScrollService, SettingsService, SignalKIdentifier, SocketChannelEvents, SocketService, StatsService, StringHelper, TestService, TimeoutHelper, Track, TrackData, TrackDataKey, TrackDataUnit, TrackImportJob, TrackRecord, TracksService, UserDetails, UserDetailsService, UserService, VoiceEntry, WebAuthnService, provideClientSdk };
