import {Component} from '@angular/core';
import {VerifySessionService} from "./verify-session.service";
import {MessageModule} from "primeng/message";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {InputOtpModule} from "primeng/inputotp";
import {NgIf} from "@angular/common";
import validator from "validator";

@Component({
  selector: 'app-verify-session',
  imports: [
    MessageModule,
    FormsModule,
    ButtonModule,
    InputOtpModule,
    NgIf
  ],
  templateUrl: './verify-session.component.html',
  standalone: true,
  styleUrl: './verify-session.component.scss'
})
export class VerifySessionComponent {

  constructor(public service: VerifySessionService) {
    //
  }

  paste(event: ClipboardEvent) {
    navigator.clipboard.readText().then(
      text => {
        if (text.length !== 6 && !validator.isNumeric(text.toString())) {
          return;
        }
        this.service.token = text;
        this.service.checkToken().then();
      }
    );
  }

  close() {
    this.service.verifyFail.next();
  }

}
