<div *ngIf="service.visibility" class="security-query-wrapper">
  <div class="security-query-container">
    <div class="title">
      <i class="fa-regular fa-circle-question"></i>
      <span>Sicherheitsabfrage</span>
    </div>
    <div class="text">
      {{ service.text }}
    </div>
    <div class="buttons">
      <p-button *ngIf="service.yesButton" (onClick)="service.setResult(result.Yes)" label="Ja" [raised]="true" severity="success" />
      <p-button *ngIf="service.noButton" (onClick)="service.setResult(result.No)" label="Nein" [raised]="true" severity="danger" />
      <p-button *ngIf="service.cancelButton" (onClick)="service.setResult(result.Cancel)" label="Abbrechen" variant="text" severity="secondary" />
    </div>
  </div>
</div>
