import {Component, HostListener, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {SortComponent} from "./sort/sort.component";
import {AppService} from "./app.service";
import {AlertModalComponent} from "./alert-modal/alert-modal.component";
import {LoaderComponent} from "./loader/loader.component";
import {SecurityQueryComponent} from "./securityQuery/securityQuery.component";
import {IssueComponent} from "./issue/issue.component";
import {CrewEditorComponent} from "./crew-editor/crew-editor.component";
import {FooterComponent} from "./footer/footer.component";
import {VideoPlayerComponent} from "./video-player/video-player.component";
import {VerifySessionComponent} from "./verfiy-session/verify-session.component";
import {InputIconModule} from "primeng/inputicon";
import {FormsModule} from "@angular/forms";
import {TooltipModule} from "primeng/tooltip";
import {MenuModule} from "primeng/menu";
import {ButtonModule} from "primeng/button";
import {
  AuthService,
  BaseService,
  ClientSdkModule,
  CryptoService,
  LogPrefix,
  StatsService
} from "logbuch-client-sdk";
import {AlertModalService} from "./alert-modal/alert-modal.service";
import {updatePrimaryPalette} from "@primeng/themes";
import {filter} from "rxjs";
import {NativeAppService} from "../services/NativeAppService";
import {ScrollPositionService} from "../services/scrollPosition.service";
import {HeaderComponent} from "./header/header.component";

@Component({
  selector: 'app-root',
  imports: [
    CommonModule,
    RouterOutlet,
    SortComponent,
    AlertModalComponent,
    LoaderComponent,
    SecurityQueryComponent,
    IssueComponent,
    CrewEditorComponent,
    FooterComponent,
    VideoPlayerComponent,
    VerifySessionComponent,
    InputIconModule,
    FormsModule,
    TooltipModule,
    MenuModule,
    ButtonModule,
    ClientSdkModule,
    HeaderComponent
  ],
  templateUrl: './app.component.html',
  standalone: true,
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'vdr-client';
  searchValue: string = '';
  stats?: { miles: number, journals: number };

  constructor(public appService: AppService,
              private route: ActivatedRoute,
              private baseService: BaseService,
              private alertModal: AlertModalService,
              private router: Router,
              private crypto: CryptoService,
              private nativeAppService: NativeAppService,
              private scrollService: ScrollPositionService,
              private authService: AuthService,
              private statsService: StatsService) {

    this.baseService.errorOccurred.subscribe((error) => {
      this.alertModal.show(error);
    });

    this.baseService.unauthorizedErrorOccurred.subscribe(() => {
      nativeAppService.callNative('unauthorized').then();
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (localStorage.getItem('app') !== undefined) {
        nativeAppService.callNative('setUrl', event.url).then();
      }
    });

  }

  @HostListener('window:beforeunload')
  saveScrollPosition() {
    this.scrollService.saveScrollPosition();
  }

  async ngOnInit() {
    this.changePrimaryColor();
    this.scrollService.extendedScrollService.scrollToTop();

    await this.waitForRouteInitialization();

    const authorization = this.route.snapshot.queryParamMap.get('authorization');

    if (authorization != null) {

      if (this.route.snapshot.queryParamMap.has('app')) {
        localStorage.setItem('app', "1");
      }

      this.baseService.setAuthorizationToken(authorization);
      await this.sendPublicKey();
      window.location.href = '/';
      return;
    }

    const secret = this.route.snapshot.paramMap.get('secret');
    if (secret != null) {
      return;
    }

    await this.appService.checkAuth();

    this.setStats().then();

    /* this.ownMembership = await this.services.membership.getOwnMembership();
    this.appService.currentUserChanged.subscribe(async () => {
      this.ownMembership = await this.services.membership.getOwnMembership();
    }); */

    window.addEventListener("beforeunload", (e) => {
      if (this.appService.isDirty) {
        const confirmationMessage = "Sie haben ungespeicherte Änderungen. Wollen Sie die Seite wirklich verlassen?";
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
      return;
    });

    await this.scrollService.restoreScrollPosition();
  }

  async sendPublicKey() {
    try {
      this.crypto.setKeys();
      while (this.crypto.clientPubKey == null) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      await this.authService.savePubKey(this.crypto.clientPubKey);
    } catch (e) {
      console.warn(LogPrefix.W, e);
    }
  }

  async setStats() {
    while (!this.appService.isAuth) {
      await new Promise(resolve => setTimeout(resolve, 200));
    }
    this.stats = await this.statsService.getStats();
  }

  search(searchValue: string) {
    this.appService.searchChanged.next(searchValue);
  }

  changePrimaryColor() {
    updatePrimaryPalette({
      50: '#4576d2',
      100: '#4075d8',
      200: '#2f6bda',
      300: '#316ad6',
      400: '#336bd4',
      500: '#0b53da',
      600: '#2c67d6',
      700: '#2261d8',
      800: '#134db9',
      900: '#0f55d8',
      950: '#0b53da'
    });
  }

  displayHeadline() {
    return localStorage.getItem('app') != "1";
  }

  async waitForRouteInitialization() {
    return new Promise<void>(resolve => {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        resolve();
      });
    });
  }

}
