import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {FormsModule} from "@angular/forms";
import validator from "validator";
import {AlertModalService} from "../../alert-modal/alert-modal.service";
import {LoaderService} from "../../loader/loader.service";
import {TooltipModule} from "primeng/tooltip";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {ClipboardHelper, CrewMember, Journal, RegistryService, StringHelper} from "logbuch-client-sdk";
import {InputJournalShareMetadata} from "logbuch.misc";
import {AutoComplete, AutoCompleteCompleteEvent, AutoCompleteSelectEvent} from "primeng/autocomplete";

@Component({
  selector: 'app-journal-share',
  imports: [
    InputGroupModule,
    InputGroupAddonModule,
    FormsModule,
    TooltipModule,
    ButtonModule,
    InputTextModule,
    AutoComplete
  ],
  templateUrl: './journal-share.component.html',
  standalone: true,
  styleUrl: './journal-share.component.scss'
})
export class JournalShareComponent implements OnInit {
  isShareEnabled = false;

  contacts: CrewMember[] = [];
  nameList: string[] = [];
  emailList: string[] = [];

  email = '';
  name = '';

  get url() {
    return `https://törn.link/${this.journal?.permalink}`;
  }

  @Input() journal?: Journal;
  @Output() close = new EventEmitter();

  constructor(private alertService: AlertModalService,
              private loader: LoaderService,
              private stringHelper: StringHelper,
              private clipboardHelper: ClipboardHelper,
              private services: RegistryService) {
  }

  async ngOnInit() {
    while (this.journal === undefined) {
      await new Promise(r => setTimeout(r, 200));
    }
    this.isShareEnabled = this.journal?.permalink !== undefined && this.journal?.permalink !== '';
    await this.loadContacts();
  }

  async loadContacts() {
    try {
      this.contacts = await this.services.crewService.getCrewMembers();
      this.nameList = this.contacts.map(c => c.name);
      this.emailList = this.contacts.map(c => c.email);
    } catch (e) {
      this.alertService.show('Es ist leider ein Fehler beim laden deiner Kontakte aufgetreten.');
    }
  }

  searchName(event: AutoCompleteCompleteEvent) {
    const query = event.query.toLowerCase();
    this.nameList = this.contacts
      .filter(c => c.name.toLowerCase().includes(query))
      .map(c => c.name);
  }

  searchMail(event: AutoCompleteCompleteEvent) {
    const query = event.query.toLowerCase();
    this.emailList = this.contacts
      .filter(c => c.email?.toLowerCase().includes(query))
      .map(c => c.email);
  }

  onNameSelect(event: AutoCompleteSelectEvent) {
    const selectedContact = this.contacts.find(c => c.name === event.value);
    if (selectedContact) {
      this.email = selectedContact.email;
    }
  }

  onEmailSelect(event: AutoCompleteSelectEvent) {
    const selectedContact = this.contacts.find(c => c.email === event.value);
    if (selectedContact) {
      this.name = selectedContact.name;
    }
  }

  async share() {

    if (this.journal == undefined) {
      return;
    }

    if (this.email != '' && !validator.isEmail(this.email?.toString())) {
      this.alertService.show('Die E-Mail-Adresse ist ungültig.');
      return;
    }

    if (this.name == '') {
      this.alertService.show('Der Name darf nicht leer sein.');
      return;
    }

    try {
      this.loader.visibility = true;

      const shareMetadata: InputJournalShareMetadata = {
        journalId: this.journal.id,
        targetMailAddress: this.email,
        targetReceiptName: this.name
      }

      await this.services.journal.shareJournal(shareMetadata);
      this.isShareEnabled = true;
      this.email = '';
      this.name = '';
    } finally {
      this.loader.visibility = false;
    }
  }

  async disableShare() {
    try {
      this.loader.visibility = true;
      await this.services.tracks.deletePermalink(this.journal!.id);
      this.journal!.permalink = undefined;
      this.isShareEnabled = false;
    } finally {
      this.loader.visibility = false;
    }
  }

}
