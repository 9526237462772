import {Routes} from '@angular/router';
import {TracksComponent} from "./tracks/tracks.component";
import {TrackComponent} from "./track/track.component";
import {hasUnsavedChangesGuard} from "./guards/unsave-changes.guard";
import {JournalEditorComponent} from "./journal/journal-editor/journal-editor.component";
import {authGuard} from "./guards/auth.guard";
import {NewJournalOverviewComponent} from "./new-journal-overview/new-journal-overview.component";
import {JournalPreviewComponent} from "./journal/journal-preview/journal-preview.component";
import {CrewsComponent} from "./crews/crews.component";
import {ContactsComponent} from "./contacts/contacts.component";
import {NewLoginComponent} from "./new-login/new-login.component";
import {MaintanceComponent} from "./maintance/maintance.component";
import {SettingsDataTabComponent} from "./account/settings-data-tab/settings-data-tab.component";
import {SettingsSecurityTabComponent} from "./account/settings-security-tab/settings-security-tab.component";

export const routes: Routes = [
  {path: 'tracks', component: TracksComponent, canDeactivate: [hasUnsavedChangesGuard], canActivate: [authGuard]},
  {
    path: 'track/:gateway/:track',
    component: TrackComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    canActivate: [authGuard]
  },
  {
    path: 'journal',
    component: NewJournalOverviewComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    canActivate: [authGuard]
  },
  {
    path: 'journal/:id',
    component: JournalEditorComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    canActivate: [authGuard]
  },
  {
    path: 'journal/:id/:edit',
    component: JournalEditorComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    canActivate: [authGuard]
  },
  {
    path: 'crews',
    component: CrewsComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    canActivate: [authGuard]
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    canActivate: [authGuard]
  },
  {path: 'login', component: NewLoginComponent},
  {path: 'login/:secret', component: NewLoginComponent},
  {path: 'profile', component: SettingsDataTabComponent, canActivate: [authGuard]},
  {path: 'security', component: SettingsSecurityTabComponent, canActivate: [authGuard]},
  {path: 'share/:link', component: JournalPreviewComponent},
  {path: 'huston', component: MaintanceComponent},
  {path: '**', redirectTo: '/journal'}
];
