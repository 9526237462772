<div *ngIf="selectedJournal == null" class="journal-wrapper">

  <div class="journal-header">
    <div class="row" style="justify-content: space-between; align-items: center; gap: 1rem;">
      <div *ngIf="journals !== undefined && journals.length > 0" class="title">
        Deine Törns
      </div>
      <div *ngIf="journals !== undefined && journals.length > 0"
           (click)="creationModal = true"
           class="new-journal">
        <i class="fa-solid fa-square-plus"></i>
        <label>Neuen Törn anlegen</label>
      </div>
    </div>

    <div *ngIf="journals !== undefined && journals.length > 0" class="welcome-text">
      <p>
        An dieser Stelle findest du eine Übersicht deiner Törns. Du kannst hier neue Törns anlegen, bestehende Törns bearbeiten oder löschen. Klicke auf einen Törn um die Details zu sehen oder zu bearbeiten.
      </p>
    </div>
  </div>

  <div *ngIf="journals !== undefined" class="journal-container" [ngClass]="journals!.length <= 0 ? 'center' : ''">
    <div *ngIf="!loader.visibility && journals.length <= 0" class="welcome">
      <img src="assets/logbook.png">
      <div class="text">
        <h1>Dein Logbuch ist noch leer...</h1>
        <span>
        Dein digitales Logbuch ist noch leer. Dilog hilft dir nun bei der Strukturierung und Visualisierung deiner Törns. Auch zurückliegende Törns lassen sich nun komfortabel visualisieren. Nachdem du einen Törn mit den Details zur Crew, zum Schiff, und zum Datum angelegt hast, kannst du beginnen es mit weiteren Inhalten zu füllen. Du kannst nun für jeden einzelnen Tag deines Törns Ereignisse hinzufügen. Wie ist meine Position, das Wetter, der Kurs, die Geschwindigkeit, die Besegelung. Was hat mich beeindruckt, wie habe ich mich gefühlt, wie war die Stimmung etc.
      </span>
        <span>
        Auch Fotos und Videos können nun den Tagen zugeordnet werden.
      </span>
        <!-- <div class="alert">
          <img src="assets/warning.svg">
          <div>An dieser Stelle werden deine Reisen angezeigt, sobald vorhanden.</div>
        </div> -->
        <h2>Wie möchtest du beginnen?</h2>
        <div class="welcome-actions">
          <div class="global-create-btn" (click)="creationModal = true">
            <i class="pi pi-file-plus"></i>
            <span> Eigenen Törn anlegen</span>
          </div>
          <div class="global-create-btn" (click)="showDummyJournal()">
            <i class="pi pi-eye"></i>
            <span>Beispiel Törn ansehen</span>
          </div>
        </div>
      </div>
    </div>

    <div class="journals">
      <div *ngFor="let item of sortedJournals" class="journal-wrapper">
        <span class="year">{{ item.year > 0 ? item.year : 'kein Jahr zugewiesen' }}</span>
        <span class="line underline"></span>
        <div class="journals-list">
          <div *ngFor="let journal of item.journals" class="journal">
            <div class="head" (click)="openJournal(journal, false)">
              <div class="title row" style="align-items: center; gap: 0.5rem;">
                <img [src]="getCountryFlagIcon(journal.country)"
                     style="width: 22px; border-radius: 0.1rem;"/>
                <span [innerText]="journal?.title ?? ''"></span>
              </div>
              <div class="details">
                <div class="detail">
                  <i class="pi pi-calendar"></i>
                  <div [innerText]="journal.year"></div>
                </div>
                <div *ngIf="journal.miles != null"
                     pTooltip="Nautische Meilen"
                     tooltipPosition="bottom"
                     class="detail">
                  <i class="pi pi-map"></i>
                  <div>{{ journal.miles }} NM</div>
                </div>
              </div>
            </div>
            <div class="data-entity" (click)="openJournal(journal, false)">
              <div class="row" style="gap: 0.5rem; align-items: center;">
                <label>Land (Revier)</label>
              </div>
              <span>{{ journal.country }} ({{ journal.revier }})</span>
            </div>
            <div class="data-entity" (click)="openJournal(journal, false)">
              <label>Boot</label>
              <span>{{ journal?.boatName ?? '-/-' }} ({{ journal?.boatType ?? '-/-' }})</span>
            </div>
            <div class="actions">
              <i (click)="openJournal(journal, false)" class="pi pi-eye"></i>
              <i *ngIf="!journal.readonly" (click)="openJournal(journal, true)" class="pi pi-pencil"></i>
            </div>
          </div>
        </div>
        <span class="line splitter"></span>
      </div>
    </div>
  </div>

</div>

<!-- <app-journal-preview *ngIf="showJournalPreview" [journal]="previewJournal"
                     (close)="showJournalPreview = false; previewJournal = undefined"/> -->

<app-journal-creation *ngIf="creationModal" (created)="loadJournals($event)" (closed)="creationModal = false"/>
