import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {LogPrefix} from "../../app.config";
import {DialogModule} from "primeng/dialog";
import {SpeedDialModule} from "primeng/speeddial";
import {CrewEditorComponent} from "../../crew-editor/crew-editor.component";
import {CrewCreatorDialogComponent} from "../../crew-creator-dialog/crew-creator-dialog.component";
import {Crew, CrewMember, CrewService, Journal} from "logbuch-client-sdk";

@Component({
  selector: 'app-journal-crew',
  imports: [
    NgForOf,
    NgIf,
    DialogModule,
    SpeedDialModule,
    CrewCreatorDialogComponent
  ],
  templateUrl: './journal-crew.component.html',
  standalone: true,
  styleUrl: './journal-crew.component.scss'
})
export class JournalCrewComponent implements AfterViewInit {

  @Input() journal?: Journal;
  @Input() crew?: Crew;
  @Output() selectedCrew = new EventEmitter<Crew>();
  @Output() crewCreated = new EventEmitter<Crew>();
  @Input() readonly = false;
  @Input() disableInitialLoading = false;

  crews: Crew[] = [];
  crewMembers: CrewMember[] = [];

  showCrewSelector = false;

  get isCrewEmpty() {
    return this.crew?.skipper == null && this.crew?.coSkipper == null && this.crew?.navigator == null && this.crew?.smutje == null && this.crew?.crewMembers == null;
  }

  constructor(private crewService: CrewService) {
    //
  }

  ngAfterViewInit() {
    if (!this.disableInitialLoading) {
      this.load().then();
    }
  }

  async load() {
    try {
      while (this.journal === undefined && this.crew === undefined) {
        await new Promise(resolve => setTimeout(resolve, 100));
        console.debug(`${LogPrefix.I} Crew-Component: Waiting for journal to be loaded...`);
      }
      if (this.journal !== undefined) {
        this.crew = this.journal.crew;
        this.readonly = this.journal.readonly;
      }
      this.crews = await this.crewService.getCrews();
    } catch (e) {
      //
    } finally {
      if (this.crew === undefined) {
        this.crew = new Crew();
      }
    }

    await this.setCrewMemberImages();
  }

  async setCrewMemberImages() {
    if (this.crew?.skipper != null) {
      this.crew!.skipper!.imageUrl = await this.getCrewMemberImage(this.crew!.skipper!);
    }
    if (this.crew?.coSkipper != null) {
      this.crew!.coSkipper!.imageUrl = await this.getCrewMemberImage(this.crew!.coSkipper!);
    }
    if (this.crew?.navigator != null) {
      this.crew!.navigator!.imageUrl = await this.getCrewMemberImage(this.crew!.navigator!);
    }
    if (this.crew?.smutje != null) {
      this.crew!.smutje!.imageUrl = await this.getCrewMemberImage(this.crew!.smutje!);
    }
    for (const member of this.crew?.crewMembers ?? []) {
      member.imageUrl = await this.getCrewMemberImage(member);
    }
  }

  async getCrewMemberImage(member: CrewMember) {
    return 'assets/profile-user.svg';
  }

  onCloseCrewSelector(display: boolean) {
    this.showCrewSelector = display;
  }

}
