<div *ngIf="journal !== undefined" class="meta">
  <div class="meta-data">
    <div class="meta-item-wrapper">
      <div (click)="tryEdit()" class="meta-item sm">
        <div>
          <div>Titel:</div>
          <span *ngIf="!editMode" [innerText]="journal!.title"></span>
          <input (keydown)="keyDownEvent($event)"
                 (focusout)="cancelEdit()"
                 *ngIf="editMode" [(ngModel)]="journal!.title">
        </div>
        <i class="fa-solid fa-pen"></i>
      </div>
      <div class="meta-item sm">
        <div>
          <div>Zeitraum:</div>
          <span [innerText]="timeRange"></span>
        </div>
        <i class="pi pi-calendar-times"></i>
      </div>
      <!-- <div *ngIf="!journal?.readonly" (click)="shareButtonClicked.emit()" class="small-menu-item">
        <i class="pi pi-share-alt"></i>
        <label>Teilen</label>
      </div> -->
      <!-- <div *ngIf="!journal?.readonly" (click)="connectAppButtonClicked()" class="small-menu-item">
        <i class="pi pi-images"></i>
        <label [innerText]="connectAppButtonName()"></label>
      </div> -->
    </div>
    <div class="meta-item-wrapper">
      <div (click)="tryEdit()" class="meta-item sm">
        <div>
          <div>Land:</div>
          <span *ngIf="!editMode" [innerText]="journal!.country"></span>
          <input (keydown)="keyDownEvent($event)"
                 (focusout)="cancelEdit()"
                 *ngIf="editMode" [(ngModel)]="journal!.country">
        </div>
        <img [src]="countryFlag">
      </div>
      <div (click)="tryEdit()" class="meta-item">
        <div>
          <div>Revier:</div>
          <span *ngIf="!editMode" [innerText]="journal!.revier"></span>
          <input (keydown)="keyDownEvent($event)"
                 (focusout)="cancelEdit()"
                 *ngIf="editMode" [(ngModel)]="journal!.revier">
        </div>
        <i class="fa-solid fa-umbrella-beach"></i>
      </div>
    </div>
    <div class="meta-item-wrapper">
      <div (click)="tryEdit()" class="meta-item">
        <div>
          <div>Jahr:</div>
          <span *ngIf="!editMode" [innerText]="journal!.year"></span>
          <input (keydown)="keyDownEvent($event)"
                 (focusout)="cancelEdit()"
                 *ngIf="editMode" [(ngModel)]="journal!.year">
        </div>
        <i class="fa-solid fa-calendar-days"></i>
      </div>
      <div (click)="tryEdit()" class="meta-item">
        <div>
          <div>Meilen:</div>
          <span *ngIf="!editMode && journal != null"
                [innerText]="journal!.miles > 0 ? journal!.miles + ' sm' : 'k.A.'"></span>
          <input (keydown)="keyDownEvent($event)"
                 (focusout)="cancelEdit()"
                 *ngIf="editMode" [(ngModel)]="journal!.miles"
                 placeholder="Seemeilen">
        </div>
        <i class="fa-solid fa-route"></i>
      </div>
    </div>
    <div class="meta-item-wrapper">
      <div (click)="tryEdit()" class="meta-item sm">
        <div>
          <div>Boots-Name:</div>
          <span *ngIf="!editMode" [innerText]="journal!.boatName"></span>
          <input (keydown)="keyDownEvent($event)"
                 (focusout)="cancelEdit()"
                 *ngIf="editMode" [(ngModel)]="journal!.boatName">
        </div>
        <i class="fa-solid fa-dharmachakra"></i>
      </div>
      <div (click)="tryEdit()" class="meta-item sm">
        <div>
          <div>Boots-Typ:</div>
          <span *ngIf="!editMode" [innerText]="journal!.boatType"></span>
          <input (keydown)="keyDownEvent($event)"
                 (focusout)="cancelEdit()"
                 *ngIf="editMode" [(ngModel)]="journal!.boatType">
        </div>
        <i class="fa-solid fa-sailboat"></i>
      </div>
    </div>
    <button *ngIf="!journal.readonly" (click)="shareButtonClicked.emit()" pTooltip="Teile den Törn" class="action-button">
      <i class="pi pi-share-alt"></i>
    </button>
  </div>
  <app-journal-map-overview [journal]="journal"/>
</div>
