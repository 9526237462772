import {Component, OnInit} from '@angular/core';
import {InfoService} from "logbuch-client-sdk";

@Component({
  selector: 'app-maintance',
  imports: [],
  templateUrl: './maintance.component.html',
  standalone: true,
  styleUrl: './maintance.component.scss'
})
export class MaintanceComponent implements OnInit {

  interval?: any // Timeout

  constructor(private service: InfoService) {
    //
  }

  async ngOnInit() {
    try {
      await this.service.checkHealth();
      console.log('Service is available');
      window.location.href = '/';
    } catch (e) {
      console.warn('Service is still not available');
      this.interval = setInterval(async () => {
        try {
          await this.service.checkHealth();
          console.log('Service is available');
          window.location.href = '/';
        } catch (e) {
          console.warn('Service is still not available');
        }
      }, 1000 * 30);
    }
  }

}
