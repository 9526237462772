import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {AlertModalService} from "../../alert-modal/alert-modal.service";
import {
  AssetService,
  BaseService,
  Info,
  InfoService,
  JournalEntity,
  JournalEntityAsset,
  JournalService
} from "logbuch-client-sdk";
import {Button} from "primeng/button";
import {QRCodeComponent} from "angularx-qrcode";
import {NativeAppService} from "../../../services/NativeAppService";
import {Upload} from "tus-js-client";

interface UploadProgress {
  chunkIndex: number;
  progress: number;
  totalProgress: number;
  uploadedSize: number;
  response?: any;
}

@Component({
  selector: 'app-journal-edit-modal',
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    NgForOf,
    Button,
    QRCodeComponent
  ],
  templateUrl: './edit-modal.component.html',
  standalone: true,
  styleUrl: './edit-modal.component.scss'
})
export class EditModalComponent {

  @Input()
  journalId?: number;

  @Input()
  obj?: JournalEntity;

  appSecret?: string;

  selectedFiles?: FileList;
  files: FileObject[] = [];
  loader = false;

  info?: Info;

  @Output()
  updated: EventEmitter<boolean> = new EventEmitter<boolean>();

  get qrCodeData() {
    return `MyShip-AssetUpload;${this.journalId}#${this.appSecret}`;
  }

  constructor(private alertService: AlertModalService,
              private assetService: AssetService,
              private baseService: BaseService,
              private infoService: InfoService,
              private nativeAppService: NativeAppService,
              private journalService: JournalService) {
    //
  }

  async generateQrCode() {
    try {
      this.loader = true;
      this.appSecret = (await this.journalService.getAppSecret(this.journalId!)).secret;
    } catch (e) {
      this.alertService.show('Fehler beim Generieren des QR-Codes!');
    } finally {
      this.loader = false;
    }
  }

  async upload() {

    let filesUploaded = false;

    this.loader = true;

    try {
      this.info = await this.infoService.getInfo();
    } catch (e) {
      this.alertService.show('Fehler beim Laden der System-Informationen!');
      this.loader = false;
      return;
    }

    if (this.obj?.id != null) {

      const allowedFileType = ['image/jpeg', 'image/png', 'video/mp4', 'video/mpeg', 'video/quicktime', 'image/heic', 'image/heif'];

      if (this.files.find(f => !allowedFileType.includes(f.file.type.toLowerCase())) != null) {
        this.alertService.show('Es werden nur JPEG, PNG, MP4, MOV und MPEG Dateien unterstützt!');
        this.loader = false;
        return;
      }

      for (const file of this.files) {
        try {
          const asset = await this.assetService.createEmptyAssetObject(this.obj.id, file.file);

          this.uploadFile(asset, file).then(async () => {
            await this.assetService.finalizeAsset(asset);
            file.processed = true;
          }).catch(() => {
            file.failed = true;
            file.processed = true;
          });

        } catch (e) {
          console.error(e);
          file.failed = true;
          file.processed = true;
          return;
        }
      }

      while (this.files.some(f => !f.processed)) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      if (this.files.some(f => f.failed)) {
        this.alertService.show('Es ist ein Fehler beim Hochladen aufgetreten!');
        this.loader = false;
        return;
      } else {
        filesUploaded = true;
      }

    }

    this.loader = false;

    this.updated.emit(filesUploaded);
  }

  uploadFile(asset: JournalEntityAsset, file: FileObject) {
    return new Promise<void>(async (resolve, reject) => {
      const upload = new Upload(file.file, {
        endpoint: `${this.info?.uploadGatewayHost}/upload`,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        chunkSize: 10 * 1024 * 1024,
        metadata: {
          filename: file.file.name,
          filetype: file.file.type,
        },
        headers: {
          'X-Asset-Secret': asset.secret
        },
        onError: function (error) {
          console.log('Failed because: ' + error);
          reject();
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          file.progress = Math.round((bytesUploaded / bytesTotal) * 100);
          if (file.progress === 100) {
            file.uploaded = true;
          }
        },
        onSuccess: function () {
          file.uploaded = true;
          resolve();
        }
      });

      upload.start();
    });
  }

  close() {
    this.updated.emit(false);
  }

  filesSelected(event: any) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles != undefined) {
      this.files = [];
      for (let i = 0; i < this.selectedFiles?.length; i++) {
        const file = this.selectedFiles.item(i);
        if (file != null) {
          this.files.push(new FileObject(file));
        }
      }
    }
  }

  openAppStore() {
    window.open('https://apps.apple.com/de/app/vdr/id6478563377', '_blank');
  }

  downloadApkFile() {
    window.open(`${this.baseService.url}/app/apk`);
  }

  isApp() {
    return localStorage.getItem('app') === '1';
  }

  async openMedia() {
    await this.generateQrCode();
    this.nativeAppService.callNative('openMediaSelection', {
      journalId: this.journalId,
      journalSecret: this.appSecret
    }).then();
  }

}

export class FileObject {
  public progress = 0;
  public uploaded = false;
  public processed = false;
  public failed = false;

  constructor(public file: File) {
    //
  }
}
