<div class="tracks-wrapper">

  <app-nmea-connect-preview *ngIf="possibleGateways == undefined || possibleGateways.length === 0"/>

  <div class="gw-selection">
    <div *ngIf="gateway === undefined && possibleGateways !== undefined && possibleGateways!.length > 0" class="alert">
      <img src="assets/warning.svg">
      <span>Auf dieser Seite kannst du die Aufzeichnungen deiner BlackBox einsehen, um fortzufahren wähle bitte die entsprechende BlackBox aus.</span>
    </div>
    <div class="gw-list">
      <div *ngFor="let gw of possibleGateways" (click)="setGatewaySelection(gw.id)"
           class="gw-item" [ngClass]="gateway?.id == gw.id ? 'active' : ''">
        {{ gw.name }}
      </div>
    </div>
  </div>

  <div class="tracks">
    <div *ngFor="let track of tracks" class="track">
      <div class="head">
        <div class="title" [innerText]="track?.name ?? 'unbenannt'"></div>
        <div class="details">
          <div class="detail">
            <i class="pi pi-calendar"></i>
            <div [innerText]="getDate(track.createdAt)"></div>
          </div>
        </div>
      </div>
      <div class="data-entity">
        <label>Start</label>
        <span>{{ getTime(track.createdAt) }}</span>
      </div>
      <div class="data-entity">
        <label>Ankunft</label>
        <span>{{ getTime(track.stopAt) }}</span>
      </div>
      <div class="actions">
        <i (click)="selectTrack(track)" class="pi pi-eye"></i>
      </div>
    </div>
  </div>

  <!--<div *ngIf="tracks.length > 0" class="table-wrapper">
    <div class="table">
      <div class="table-header">
        <div>Bezeichnung</div>
        <div>Datum</div>
        <div>Start</div>
        <div>Ankunft</div>
        <div>Dauer</div>
      </div>
      <div *ngFor="let item of tracks; let i = index" class="table-content"
           [ngClass]="getRowClasses(item, i)" (click)="selectTrack(item)">
        <div>{{ item.name != null ? item.name : '-/-' }}</div>
        <div>{{ getDate(item.createdAt) }}</div>
        <div>{{ getTime(item.createdAt) }} Uhr</div>
        <div>{{ getTime(item.stopAt) }} Uhr</div>
        <div>{{ getDuration(item.createdAt, item.stopAt) }}</div>
      </div>
    </div>
  </div>-->

  <!--<div class="actions">
    <p-button *ngIf="isAdmin && !deleteMode" (onClick)="deleteMode = true; entityToDelete = []"
              label="Bearbeitungsmodus"
              severity="secondary"/>
    <p-button *ngIf="isAdmin && deleteMode" (onClick)="deleteSelection()" label="Ausgewählte Elemente löschen"
              severity="danger"/>
  </div>-->

</div>
