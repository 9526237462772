import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Menu} from "primeng/menu";
import {Button} from "primeng/button";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {UserDetails} from "logbuch-client-sdk";
import {Router, RouterLink} from "@angular/router";
import {Avatar} from "primeng/avatar";
import {Ripple} from "primeng/ripple";

@Component({
  selector: 'app-header',
  imports: [
    Menu,
    Button,
    NgIf,
    FormsModule,
    RouterLink,
    Avatar,
    NgOptimizedImage,
    Ripple
  ],
  templateUrl: './header.component.html',
  standalone: true,
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Input() isAuth: boolean = false;
  @Input() userDetails?: UserDetails;
  @Input() displayHeadline: boolean = true;
  @Input() stats?: { miles: number, journals: number };

  searchVisible: boolean = false;
  searchValue: string = '';
  @Output() searchValueChanged = new EventEmitter<string>();

  menuItems = [
    {
      label: 'Optionen',
      items: [
        {
          label: 'Törns',
          icon: 'pi pi-book',
          routerLink: '/journal'
        },
        {
          label: 'Tracks',
          icon: 'pi pi-share-alt',
          routerLink: '/tracks'
        },
        /* {
          label: 'Deine Crews',
          icon: 'pi pi-users',
          routerLink: '/crews'
        }, */
        {
          label: 'Adressbuch',
          icon: 'pi pi-address-book',
          routerLink: '/contacts'
        },
        {
          label: 'Sicherheit & Datenschutz',
          icon: 'pi pi-sliders-h',
          routerLink: '/security'
        },
      ]
    }
  ];

  constructor(private router: Router) {
  }

  get DisplayName() {
    const user = this.userDetails;
    if (user?.firstname != null && user.firstname != ''
      && user?.lastname != null && user.lastname != '') {
      return `${user.firstname} ${user.lastname}`;
    } else {
      return user?.user.email.split('@')[0];
    }
  }

  get searchAvailable() {
    return window.location.pathname.endsWith('/journal') && this.displayHeadline;
  }

  search() {
    this.searchValueChanged.emit(this.searchValue);
  }

  showSearch() {
    this.searchVisible = true;
    setTimeout(() => {
      const searchInput = document.getElementById('search-field');
      if (searchInput != null) {
        searchInput.focus();
      }
    }, 100);
  }

  async navigate(url: string) {
    await this.router.navigate([url]);
  }

}
