<div class="container">
  <div class="header offset">
    <app-header [isAuth]="appService.isAuth"
                [displayHeadline]="true"/>
  </div>
  <div class="metadata offset">
    <app-journal-entity-metadata [journal]="journal"
                                 [editMode]="false"/>
  </div>
  <div *ngIf="journal !== undefined && journal!.crew !== undefined" class="crew offset">
    <app-journal-crew [crew]="journal!.crew" [readonly]="true"/>
  </div>
  <app-journal-entities class="offset" *ngIf="journal != null"
                        [journal]="journal"
                        [editMode]="false"
                        (entitySelected)="selectedEntity = $event"/>
</div>
