import { Component } from '@angular/core';
import {AlertModalService} from "./alert-modal.service";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-alert-modal',
  imports: [
    NgIf
  ],
  templateUrl: './alert-modal.component.html',
  standalone: true,
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent {

  constructor(public service: AlertModalService) {
    //
  }

}
