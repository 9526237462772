import {AfterViewInit, Component, Input} from '@angular/core';
import {NgIf} from "@angular/common";
import moment from "moment/moment";
import {JournalStats, RegistryService} from "logbuch-client-sdk";

@Component({
  selector: 'app-journal-stats',
  imports: [
    NgIf
  ],
  templateUrl: './journal-stats.component.html',
  standalone: true,
  styleUrl: './journal-stats.component.scss'
})
export class JournalStatsComponent implements AfterViewInit {

  @Input() journalId?: number;
  @Input() trackId?: number;
  @Input() isVisible?: boolean;

  isLoaded = false;

  @Input()
  stats?: JournalStats;

  constructor(private services: RegistryService) {
    //
  }

  async ngAfterViewInit() {
    if (this.isLoaded) {
      return;
    }
    this.isLoaded = true;
    const timeout = setInterval(async () => {
      if (this.trackId != null && this.journalId != null && this.isVisible) {
        clearInterval(timeout);
        this.stats = await this.services.journal.getStats(this.journalId, this.trackId);
      }
    }, 1000)
  }

  getTime(date: Date) {
    moment.locale('de');
    return moment(new Date(date)).format('LT');
  }

  getDuration(date1: Date, date2: Date) {
    moment.locale('de');
    const diff = moment.duration(moment(date2).diff(moment(date1)));
    const formattedDuration = `${Math.floor(diff.asHours())}:${moment.utc(diff.asMilliseconds()).format('mm')}`;
    return formattedDuration;
  }

  convertTemperature(temperature: number) {
    return temperature - 273.15;
  }

  convertSpeed(mps: number) {
    if (mps === undefined) {
      return '-/-';
    }
    const factor: number = 1.94384; // metersPerSecondToKnotsConversionFactor
    return (mps * factor).toFixed(1);
  }

}

