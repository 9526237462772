export const environment = {
  production: true,
  backend: {
    host: 'backend.dilog.net',
    master: 'backend.dilog.net',
    port: 443,
    protocol: 'https'
  },
  stripe: {
    publishableKey: 'PUBLISHABLE_KEY'
  }
};
