import {Feature, Map, View} from 'ol';
import {fromLonLat} from "ol/proj";
import {XYZ} from "ol/source";
import {Layer, Tile, Vector} from "ol/layer";
import {Circle, LineString, Point, Polygon} from "ol/geom";
import {Fill, Icon, Stroke, Style} from "ol/style";
import VectorSource from "ol/source/Vector";
import {Coordinate} from "ol/coordinate";
import VectorLayer from "ol/layer/Vector";
import CircleStyle from "ol/style/Circle";
import {TrackRecord} from "logbuch-client-sdk";

export class OpenlayersExtension {

  targetFlagIcon = new Style({
    image: new Icon({
      anchor: [0.75, 256],
      scale: 0.08,
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      src: 'assets/flag.svg'
    })
  });

  startFlagIcon = new Style({
    image: new Icon({
      anchor: [0.75, 256],
      scale: 0.075,
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      src: 'assets/target.svg'
    })
  });

  marker?: Layer = undefined;

  createMap(lon: number, lat: number, elementId: string, zoom: number = 12): Map {
    return new Map({
      layers: [],
      controls: [],
      target: elementId,
      view: new View({
        center: fromLonLat([lon, lat]),
        zoom,
      }),
    });
  }

  deleteMap(map: Map): void {
    console.log("deleteMap");
    map.setTarget(undefined);
    map.dispose();
  }

  addStaticLayer(map: Map, type: "google" | "openstreetmap" | "openseamap") {
    const layer = new Tile({
      source: new XYZ({
        url: type === "google" ? 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}' :
          type === "openseamap" ? 'https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png' :
            'https://backend.dilog.net/tiles/osm/{z}/{x}/{y}',
      })
    });
    map.addLayer(layer);
  }

  setMarker(map: Map, lat: number, lon: number, removeLatest: boolean = true): void {

    if (this.marker !== undefined && removeLatest) {
      map.removeLayer(this.marker);
    }

    const markerIcon = new Style({
      image: new Icon({
        anchor: [0.5, 256],
        scale: 0.08,
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: 'assets/pin.svg'
      })
    });

    const iconFeature = new Feature({
      geometry: new Point(fromLonLat([lon, lat]))
    });
    iconFeature.setStyle(markerIcon);

    const vectorSource = new VectorSource({
      features: [iconFeature]
    });
    const positionLayer = new Vector({
      source: vectorSource
    });

    if (removeLatest) {
      this.marker = positionLayer;
    }

    map.addLayer(positionLayer);
  }

  deleteMarker(map: Map): void {
    if (this.marker !== undefined) {
      map.removeLayer(this.marker);
    }
  }

  drawTrackWaypoints(lonLatCoords: number[], record: TrackRecord): {
    layer: VectorLayer<VectorSource<Feature<Point>>>,
    features: Feature<Point>[]
  } {
    const features = [];

    const point = new Point(fromLonLat(lonLatCoords));
    const feature = new Feature({
      geometry: point
    });

    // feature.set('record', record);

    features.push(feature);

    const vectorSource = new VectorSource({
      features: [feature]
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      visible: true,
      minZoom: 13,
      zIndex: 999,
      style: new Style({
        image: new CircleStyle({
          radius: 12, // Festgelegte Radiusgröße in Pixeln
          fill: new Fill({
            color: '#c000ff'
          })
        })
      })
    });

    return {
      layer: vectorLayer,
      features
    };
  }

  createRotatedIcon(lonLatCoords: number[], rotation: number) {
    const iconFeature = new Feature({
      geometry: new Point(fromLonLat(lonLatCoords)),
    });

    const iconStyle = new Style({
      image: new Icon({
        src: 'assets/fast-forward.png',
        rotation: rotation, // Rotation in Radianten
        rotateWithView: false,
        width: 16,
        height: 16,
        anchor: [0.5, 0.5], // Zentriert das Bild
      }),
    });

    iconFeature.setStyle(iconStyle);

    const vectorSource = new VectorSource({
      features: [iconFeature],
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      minZoom: 13,
      zIndex: 999,
    });

    return vectorLayer;
  }

  drawLine(startFromLonLat: [number, number], endFromLonLat: [number, number]): Layer | null {

    if (startFromLonLat[0] === 0 || startFromLonLat[1] === 0 || endFromLonLat[0] === 0 || endFromLonLat[1] === 0) {
      return null;
    }

    const startPoint = fromLonLat(startFromLonLat);
    const endPoint = fromLonLat(endFromLonLat);

    const lineFeature = new LineString([startPoint, endPoint]);
    const lineLayer = new Vector({
      source: new VectorSource({
        features: [new Feature(lineFeature)],
      }),
      style: new Style({
        stroke: new Stroke({
          color: '#c000ff',
          width: 3,
        }),
      }),
    });

    return lineLayer;
  }

  updateMapView(map: Map, lat: number, lng: number): void {
    map.getView().centerOn(fromLonLat([lng, lat]), map.getSize()!, [map.getSize()![0] / 2, map.getSize()![1] / 2]);
  }

  centerMapOnCoordinates(map: Map, coordinates: Coordinate[]) {
    const convertedCoordinates = coordinates.map(cord => fromLonLat([cord[0], cord[1]]));

    const vectorSource = new VectorSource();
    convertedCoordinates.forEach(cord => {
      vectorSource.addFeature(new Feature(new Point(cord)));
    });

    const waypointsLayer = new VectorLayer({
      source: vectorSource,
      visible: true,
      style: new Style()
    });

    map.addLayer(waypointsLayer);
    map.getView().fit(vectorSource.getExtent(), {
      padding: [32, 32, 32, 32],
    });
  }

  setFlag(lat: number, lon: number, icon: Style = this.targetFlagIcon): VectorLayer<VectorSource<Feature<Point>>> {

    const iconFeature = new Feature({
      geometry: new Point(fromLonLat([lon, lat]))
    });
    iconFeature.setStyle(icon);

    const vectorSource = new VectorSource({
      features: [iconFeature]
    });

    return new Vector({
      source: vectorSource
    });

  }

  zoom(map: Map, scale: number): void {
    map.getView().setZoom(scale);
  }

}
