<div class="container">
  <section>
    <p-message *ngIf="!sessionVerified" severity="info"
               text="Um Änderungen an den folgenden Informationen vorzunehmen, ist eine zusätzliche Verifizierung der Sitzung notwendig!"></p-message>
    <p-button *ngIf="!sessionVerified" (click)="verifySessionOnClick()" label="Verifizieren"
              severity="secondary"></p-button>
  </section>
  <section>
    <div class="profile-title">Passwort ändern</div>
    <p-float-label>
      <p-password class="p-fluid" id="pw1" [(ngModel)]="pw1" [feedback]="true" [toggleMask]="true"
                  [disabled]="!sessionVerified"></p-password>
      <label for="pw1">Neues Passwort</label>
    </p-float-label>
    <p-float-label>
      <p-password class="p-fluid" id="pw2" [(ngModel)]="pw2" [feedback]="false" [toggleMask]="false"
                  [disabled]="!sessionVerified"></p-password>
      <label for="pw2">Neues Passwort wiederholen</label>
    </p-float-label>
    <p-button (onClick)="changePassword()" [disabled]="!sessionVerified" label="Übernehmen"
              icon="pi pi-check"></p-button>
  </section>
  <section>
    <div class="profile-title">Passkey-Authentifizierung</div>
    <div class="keys">
      <p-progressBar *ngIf="webAuthnRegistrations == undefined"
                     mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
      <p-message *ngIf="webAuthnRegistrations != undefined &&
                        webAuthnRegistrations.length == 0"
                 severity="info" class="p-fluid"
                 text="Für deinen Account sind keine Passkeys hinterlegt!"></p-message>
      <div *ngFor="let key of webAuthnRegistrations" class="key">
        <i class="pi pi-key"></i>
        <div class="desc">
          <label [innerText]="key.description == null ? '-/-' : key.description"></label>
          <span>Erstellt: {{ formatDate(key.createdAt) }}</span>
        </div>
        <p-button (click)="key.detailsModalVisible = true" [disabled]="!sessionVerified"
                  icon="pi pi-external-link" label="Details"
                  [style]="{'font-size': '0.75rem'}"></p-button>
        <p-dialog header="Passkey: {{ key.description == null ? '-/-' : key.description }}"
                  [(visible)]="key.detailsModalVisible"
                  [style]="{width: '50vw'}">
          <p-float-label>
            <input pInputText id="description" [(ngModel)]="key.description"
                   [autofocus]="false"/>
            <label for="description">Bezeichnung</label>
          </p-float-label>
          <p>Erstellt: {{ formatDate(key.createdAt) }}</p>
          <div class="row" style="gap: 1rem;">
            <p-button (onClick)="updateKey(key)" label="Übernehmen"
                      icon="pi pi-check" [autofocus]="false"></p-button>
            <p-button (onClick)="deleteKey(key)" label="Löschen" severity="danger"
                      icon="pi pi-trash" [autofocus]="false"></p-button>
          </div>
        </p-dialog>
      </div>
    </div>
    <p-button (click)="registerClient()" [disabled]="!sessionVerified" label="Neues Gerät Registrieren"
              icon="pi pi-plus-circle"></p-button>
  </section>
</div>
