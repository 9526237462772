<div class="modal-wrapper">
  <div class="card">
    <div class="card-title">
      <i class="pi pi-images"></i>
      <span>Medien-Upload</span>
    </div>
    <div class="sections">
      <div class="section">
        <label>Manueller Upload</label>
        <small>An dieser Stelle kannst du Medien außerhalb deiner Mediathek in dein Logbuch hochladen. Diese Funktion eignet sich z.B. perfekt für bereits geschnittene Videos.</small>
        <div *ngIf="obj?.id != null" class="form-group file-area">
          <input (change)="filesSelected($event)" [multiple]="true" type="file" name="images" id="images"/>
          <div class="file-dummy">
            <div class="placeholder" *ngIf="files.length <= 0">keine Dateien ausgewählt</div>
            <div class="file" *ngFor="let file of files">
              <div [innerText]="file.file.name"></div>
              <div *ngIf="!file.uploaded && !file.failed" class="progress">{{ file.progress }} %</div>
              <div *ngIf="file.uploaded && file.processed && !file.failed" class="progress success">fertig</div>
              <div *ngIf="file.uploaded && !file.processed && !file.failed" class="progress process">verarbeite...</div>
              <div *ngIf="file.failed" class="progress error">fehlgeschlagen</div>
            </div>
          </div>
        </div>
        <div *ngIf="loader" class="loader">
          <div class="lds-dual-ring"></div>
        </div>
        <div *ngIf="!loader" class="actions">
          <p-button (click)="upload()" [disabled]="files.length == 0" [rounded]="true" icon="pi pi-upload" iconPos="right" />
        </div>
      </div>
      <span class="separator"></span>
      <div class="section">
        <label>DiLog - Connect</label>
        <small>Mit DiLog - Connect hast du die Möglichkeit Medien direkt aus deiner Mediathek in dein Logbuch hochzuladen.</small>
        <p-button (click)="openMedia()" *ngIf="isApp()" label="Zur Mediathek" icon="pi pi-arrow-circle-right" iconPos="right" />
        <div *ngIf="!isApp()" class="qr">
          <div class="qr-code" [ngClass]="appSecret === undefined ? 'btn' : 'qr-code-visible'">
            <qrcode *ngIf="appSecret !== undefined" [qrdata]="qrCodeData"
                    [width]="128"
                    [margin]="0"
                    [style]="{width: 'auto'}"
                    colorDark="#222"
                    colorLight="#fff"
            ></qrcode>
            <div *ngIf="appSecret === undefined" (click)="generateQrCode()" class="load-qr-btn">
              <i class="fa fa-repeat"></i>
              <span class="qr-load-test">Code<br>generieren</span>
            </div>
          </div>
          <div class="app-links">
            <div (click)="openAppStore()" class="app-store">
              <i class="fa-brands fa-apple"></i>
              <div>
                <small>Download on the</small>
                <label>App Store</label>
              </div>
            </div>
            <div (click)="downloadApkFile()" class="app-store">
              <i class="fa-brands fa-android" style="font-size: 1.25rem;"></i>
              <div>
                <small>Download for</small>
                <label>Android</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!loader" class="close">
      <i class="pi pi-times-circle" (click)="close()"></i>
    </div>
  </div>
</div>
