import {Component, EventEmitter, HostListener, Output} from '@angular/core';
import {PicturePreviewService} from "./picture-preview.service";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {MapComponent} from "../map/map.component";
import {MapService} from "../map/map.service";
import 'video.js';
import {JournalEntityAsset, BaseService} from "logbuch-client-sdk";

@Component({
  selector: 'app-picture-preview',
  imports: [
    NgForOf,
    NgIf,
    MapComponent,
    NgClass,
  ],
  templateUrl: './picture-preview.component.html',
  standalone: true,
  styleUrl: './picture-preview.component.scss'
})
export class PicturePreviewComponent {

  @Output() close = new EventEmitter<void>();

  fullscreen = false;

  get videoOptions() {
    return {
      fluid: true,
      aspectRatio: '16:9',
      autoplay: true,
      controls: true,
      sources: [{
        src: this.service.getVideoUrl(),
        type: this.service.getVideoMimeType()
      }]
    };
  }

  constructor(public service: PicturePreviewService, public mapService: MapService,
              public base: BaseService) {
    this.service.closeComponent.subscribe(() => this.close.emit());
  }

  select(asset: JournalEntityAsset) {
    this.service.selectedAsset = asset;
    this.mapService.selectionChanged(asset);
    this.service.loadNextAndPreviousAssets();
  }

  getPictures() {
    return this.service.assets.filter(a => this.service.isImage(a));
  }

  @HostListener('window:keydown', ['$event'])
  keyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.fullscreen = false;
    } else if (event.key === 'ArrowLeft') {
      this.service.selectPrevious();
    } else if (event.key === 'ArrowRight') {
      this.service.selectNext();
    }
  }

}
