import {AfterViewInit, Component} from '@angular/core';
import {PasswordModule} from "primeng/password";
import {ButtonModule} from "primeng/button";
import {FormsModule} from "@angular/forms";
import {InputOtpModule} from "primeng/inputotp";
import {NgForOf, NgIf} from "@angular/common";
import {InputTextModule} from "primeng/inputtext";
import {AlertModalService, AlertType} from "../../alert-modal/alert-modal.service";
import {LoaderService} from "../../loader/loader.service";
import moment from "moment";
import {ContextMenuModule} from "primeng/contextmenu";
import {DialogModule} from "primeng/dialog";
import {MessagesModule} from "primeng/messages";
import {MessageModule} from "primeng/message";
import {SkeletonModule} from "primeng/skeleton";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {ProgressBarModule} from "primeng/progressbar";
import {VerifySessionService} from "../../verfiy-session/verify-session.service";
import {
  AuthService,
  UserDetails,
  UserDetailsService,
  UserService,
  WebAuthnRegistration,
  WebAuthnService
} from "logbuch-client-sdk";
import {FloatLabel} from "primeng/floatlabel";

@Component({
  selector: 'app-settings-security-tab',
  imports: [
    PasswordModule,
    ButtonModule,
    FormsModule,
    InputOtpModule,
    NgIf,
    InputTextModule,
    NgForOf,
    ContextMenuModule,
    DialogModule,
    MessagesModule,
    MessageModule,
    SkeletonModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    FloatLabel,
  ],
  templateUrl: './settings-security-tab.component.html',
  standalone: true,
  styleUrl: './settings-security-tab.component.scss'
})
export class SettingsSecurityTabComponent implements AfterViewInit {

  sessionVerified: boolean = false;

  user?: UserDetails;
  webAuthnRegistrations?: WebAuthnRegistration[];

  pw1: string = '';
  pw2: string = '';

  constructor(private verifySession: VerifySessionService,
              private alertService: AlertModalService,
              private authService: AuthService,
              private userDetails: UserDetailsService,
              private userService: UserService,
              private webAuthnService: WebAuthnService,
              private loader: LoaderService) {
    //
  }

  async ngAfterViewInit() {
    try {
      this.loader.visibility = true;
      const session = await this.authService.checkSession();
      if (!session.isVerified) {
        await this.verifySession.verifySession();
      }
      this.sessionVerified = true;
    } finally {
      this.loader.visibility = false;
      try {
        this.user = await this.userDetails.getUserDetails() ?? undefined;
        this.loadWebAuthnRegistrations().then();
      } catch (e) {
        //
      }
    }
  }

  async verifySessionOnClick() {
    try {
      this.loader.visibility = true;
      await this.verifySession.verifySession();
      this.sessionVerified = true;
    } finally {
      this.loader.visibility = false;
    }
  }

  async changePassword() {
    if (this.pw1 !== this.pw2) {
      this.alertService.show('Die Passwörter stimmen nicht überein, bitte überprüfe deine Eingaben!', 6000, AlertType.Error);
      return;
    }
    this.loader.visibility = true;
    try {
      await this.userService.changePassword(this.pw1);
      this.alertService.show('Passwort wurde erfolgreich geändert!', 6000, AlertType.Success);
      this.pw1 = '';
      this.pw2 = '';
    } finally {
      this.loader.visibility = false;
    }
  }

  async loadWebAuthnRegistrations() {
    this.webAuthnRegistrations = await this.webAuthnService.getRegistrations();
    console.log(this.webAuthnRegistrations);
  }

  async registerClient() {
    this.loader.visibility = true;
    try {
      await this.webAuthnService.registerClient();
      await this.loadWebAuthnRegistrations();
      this.alertService
        .show('PassKey wurde erfolgreich hinzugefügt!',
          6000, AlertType.Success);
    } catch (e) {
      if (!this.alertService.visibility) {
        this.alertService
          .show('Der PassKey konnte nicht registriert werden.',
            6000, AlertType.Error);
      }
      console.log(e);
    } finally {
      this.loader.visibility = false;
    }
  }

  async updateKey(key: WebAuthnRegistration) {
    key.detailsModalVisible = false;
    this.loader.visibility = true;
    try {
      await this.webAuthnService.updateRegistration(key);
      this.alertService
        .show('PassKey wurde erfolgreich aktualisiert!',
          6000, AlertType.Success);
    } catch (e) {
      //
    } finally {
      this.loader.visibility = false;
    }
  }

  async deleteKey(key: WebAuthnRegistration) {
    key.detailsModalVisible = false;
    this.loader.visibility = true;
    try {
      await this.webAuthnService.deleteRegistration(key);
      await this.loadWebAuthnRegistrations();
      this.alertService
        .show('PassKey wurde erfolgreich entfernt!',
          6000, AlertType.Success);
    } catch (e) {
      //
    } finally {
      this.loader.visibility = false;
    }
  }

  formatDate(date: Date) {
    return moment(new Date(date)).format('DD.MM.YYYY HH:mm:ss');
  }

}
