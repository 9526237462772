import {AfterViewInit, Component, Input, Output} from '@angular/core';
import {MapService} from "./map.service";

@Component({
  selector: 'app-map',
  imports: [],
  templateUrl: './map.component.html',
  standalone: true,
  styleUrl: './map.component.scss'
})
export class MapComponent {

  constructor(public service: MapService) {
    //
  }

}
