import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {JournalMapOverviewComponent} from "../journal-map-overview/journal-map-overview.component";
import {Journal, RegistryService} from "logbuch-client-sdk";
import moment from "moment/moment";
import {Tooltip} from "primeng/tooltip";

@Component({
  selector: 'app-journal-entity-metadata',
  imports: [
    FormsModule,
    NgIf,
    JournalMapOverviewComponent,
    Tooltip
  ],
  templateUrl: './journal-entity-metadata.component.html',
  standalone: true,
  styleUrl: './journal-entity-metadata.component.scss'
})
export class JournalEntityMetadataComponent implements AfterViewInit {

  @Input() journal?: Journal;

  @Output() save = new EventEmitter<void>();

  @Output() editModeChanged = new EventEmitter<boolean>();
  @Input() editMode: boolean = false;

  @Output() shareButtonClicked = new EventEmitter<void>();

  fallbackFlagIcon = 'assets/world.svg';
  countryFlag?: string = this.fallbackFlagIcon;

  get timeRange() {
    if (this.journal === undefined || this.journal.journals.length <= 1) {
      return '-/-';
    }
    const firstDay = this.journal?.journals[0].dateOnly;
    const lastDay = this.journal?.journals[this.journal.journals.length - 1].dateOnly;
    return `${moment(firstDay).format('DD.MM')} - ${moment(lastDay).format('DD.MM.YY')}`;
  }

  constructor(private services: RegistryService) {
    //
  }

  async ngAfterViewInit() {
    while (this.journal === undefined) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    this.countryFlag = await this.setCountryFlag(this.journal.country);
  }

  tryEdit() {
    if (this.journal?.readonly) {
      return;
    }
    this.editModeChanged.emit(true);
  }

  cancelEdit() {
    this.editModeChanged.emit(false);
    this.save.emit();
    this.setCountryFlag(this.journal!.country).then(flag => this.countryFlag = flag);
  }

  keyDownEvent(event: any) {
    if (event.key === 'Enter') {
      this.cancelEdit();
    }
  }

  async setCountryFlag(country?: string) {
    try {
      if (country !== undefined && country !== '' && country.length > 3) {
        const result = await this.services.external.getCountries(country);
        for (const country of result) {
          return country.flags.svg;
        }
      }
    } catch (e) {
      //
    }
    return this.fallbackFlagIcon;
  }

}
