import {Component, OnInit} from '@angular/core';
import moment from "moment/moment";
import * as packageJson from '../../../package.json';
import {ButtonModule} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {TableModule} from "primeng/table";
import {FieldsetModule} from "primeng/fieldset";
import {NgIf} from "@angular/common";
import {Info, PortfolioSection, RegistryService} from "logbuch-client-sdk";

@Component({
  selector: 'app-footer',
  imports: [
    ButtonModule,
    DialogModule,
    TableModule,
    FieldsetModule,
    NgIf
  ],
  templateUrl: './footer.component.html',
  standalone: true,
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {

  clientVersion = `${packageJson.version}`;
  backendVersion?: string;
  info?: Info;
  sections?: PortfolioSection[];

  constructor(public services: RegistryService) {
    //
  }

  async ngOnInit() {
    const [info, sections] = await Promise.all([
      this.services.info.getInfo(),
      this.services.cms.getSections()
    ]);

    this.info = info;
    this.sections = sections;

    this.backendVersion = this.info.version;
  }

  get getYear() {
    moment.locale('de');
    return moment().format('YYYY');
  }

  getSectionTitleByKey(key: string) {
    return this.sections?.find(section => section.key === key)?.title;
  }

  getSectionTextByKey(key: string) {
    return this.sections?.find(section => section.key === key)?.text;
  }

  get getBackendVersion() {
    return this.backendVersion !== undefined ? `${this.backendVersion}` : '';
  }

}
